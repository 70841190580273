export const de = {
  monday: "Montag",
  tuesday: "Dienstag",
  wednesday: "Mittwoch",
  thursday: "Donnerstag",
  friday: "Freitag",
  saturday: "Samstag",
  sunday: "Sonntag",
  days: "Tage",
  day: "Tag",
  week: "Woche",
  weeks: "Wochen",
  month: "Monat",
  months: "Monate",
  year: "Jahr",
  years: "Jahre",
  yes: "Ja",
  no: "Nein",
  north: "N",
  south: "S",
  west: "W",
  east: "O",
  northWest: "NW",
  northEast: "NO",
  southWest: "SW",
  southEast: "SO",
  unknown: "Unbekannt",
  heading: "Bewegungsrichtung",
  speed: "Geschwindigkeit",
  accuracy: "Genauigkeit",
  timestamp: "Zeitpunkt",
  constructionSites: "Baustellen",
  constructionSiteUnknownTitle: "Unbekannt",
  constructionSiteUnknownDescription: "Baustelle nicht gefunden.",
  allEntries: "Alle",
  addOptionPrefix: "",
  addOptionSuffix: "neu anlegen",
  employee: "Mitarbeitende/r",
  myAreas: "Meine Bereiche",
  aroundPlanning: "Rund um die Planung",
  serviceCategory: "Leistungskategorie",
  planning: "Planung",
  planningDescription: "Plane deine Aufträge.",
  planningDateRange: "Planungszeitraum",
  timeApproval: "Zeitenfreigabe",
  timeApprovalDescription: "Gib die Zeiten deiner Kolonnen frei.",
  timeApprovalDurationIsLoading: "Lädt...",
  timeApprovalArrivalDuration: "Anfahrtsdauer",
  timeApprovalDepartureDuration: "Abfahrtsdauer",
  timeApprovalCommuteTypeNotAllowed: "Unbekannter Typ",
  timeApprovalCommuteTypeNoCommute: "keine",
  timeApprovalCommuteTypeDriverHome: "Zuhause - Fahrer/in",
  timeApprovalCommuteTypeDriverSite: "Betriebshof - Fahrer/in",
  timeApprovalCommuteTypePassengerHome: "Zuhause - Beifahrer/in",
  timeApprovalCommuteTypePassengerSite: "Betriebshof - Beifahrer/in",
  absence: "Abwesenheit",
  absences: "Abwesenheiten",
  absencesDescription: "Übersicht und Verwaltung von geplanten Abwesenheiten.",
  absencesTitle: "Abwesenheitsplanung",
  absenceDateRangePickerLabel: "Zeitraum",
  absencePeriod: "Zeitraum",
  absenceCategory: "Art",
  absenceCategorySchool: "Schule",
  absenceCategorySickness: "Krankheit",
  absenceCategoryTraining: "Schulung/Weiterbildung",
  absenceCategoryVacation: "Urlaub",
  absenceCategoryLeisureTimeAccount: "Freizeitkonto",
  absenceNote: "Bemerkung",
  addAbsenceSuccess: "Abwesenheit erfolgreich hinzugefügt",
  updateAbsenceSuccess: "Abwesenheit erfolgreich bearbeitet",
  deleteAbsenceSuccess: "Abwesenheit erfolgreich gelöscht",
  absenceEditButton: "Bearbeiten",
  absenceFormEditHeadline: "Abwesenheit bearbeiten",
  absenceFormAddHeadline: "Abwesenheit hinzufügen",
  addAbsence: "Abwesenheit hinzufügen",
  absenceInfoText: "Mitarbeiter/in ist zum ausgewählten Zeitraum abwesend!",
  absenceDeleteModalTitle: "Abwesenheit löschen",
  absenceDeleteModalInfo:
    "Bist du sicher, dass du diese Abwesenheit löschen möchtest? <br/><br/>" +
    "Eventuell sind bereits Zeiten für diese Abwesenheit exportiert worden " +
    "oder Baustellen aufgrund der geplanten Abwesenheit anders besetzt worden.",
  absenceDeleteModalConfirm: "Verstanden",
  absencesToggleCalendarViewTooltip: "Kalenderansicht",
  absencesToggleTableViewTooltip: "Tabellenansicht",
  timeTrackingPositionModalConfirm: "Verstanden",
  absenceEmptySearchResult:
    "Für die ausgewählten Suchkriterien konnten keine Abwesenheiten gefunden werden.",
  absenceCategorySelectPlaceholder: "Art auswählen",
  absenceMinDateError:
    "Das Datum darf maximal 1 Jahr in der Vergangenheit liegen",
  absenceOverlappingError: "Für den Zeitraum besteht bereits eine Abwesenheit.",
  absenceInfoModalTitle: "Abwesenheit von {employeeName}",
  absenceInfoModalEmployeeUnknown: "Unbekannt",
  absenceInfoModalAbsenceDeleted:
    "Zugehörige Abwesenheitsplanung wurde gelöscht.",
  endDateAfterStartDate: "Startdatum muss vor Enddatum liegen",
  timeRange: "Zeitraum",
  loading: "Lade Daten",
  photoGallery: "Foto-Galerie",
  projectsJournal: "Baubuch",
  projectsJournalDescription: "Sieh wie gut deine Baustellen laufen.",
  projectsJournalBreadcrumbs: "Baubuch",
  controllingAccountSubgroupFilter: "Konten",
  controllingAccountSubgroupAll: "Alle Buchungen",
  controllingAccountSubgroupLabelToolsInternal: "Intern",
  controllingAccountSubgroupLabelToolsExternal: "Extern",
  controllingAccountSubgroupLabelForeignInternal: "Intern",
  controllingAccountSubgroupLabelForeignExternal: "Extern",
  controllingAccountSubgroupLabelRevenueInvoiced: "geschr.AbRe.",
  controllingAccountSubgroupLabelRevenuePartiallyPaid: "erhaltene Anzahlungen",
  controllingAccountSubgroupLabelRevenueProceeds: "Erlöse",
  controllingProjectsFilterLabel: "Projekte",
  controllingProjectsFilterPlaceholder: "Projekte auswählen",
  controllingBusinessLineFilterLabel: "Geschäftsfelder",
  controllingBusinessLineFilterPlaceholder: "Geschäftsfelder auswählen",
  controllingServiceCategoryFilterLabel: "Leistungskategorien (Arbeitspakete)",
  controllingServiceCategoryOrderManagementFilterLabel:
    "Leistungskategorien (Auftragsverwaltung)",
  controllingServiceCategoryFilterPlaceholder: "Leistungskategorien auswählen",
  controllingProjectsManagersFilterLabel: "Bauleiter",
  controllingProjectsClientNamesFilterLabel: "Auftraggeber",
  controllingDateRangeFilterLabel: "Buchungszeitraum",
  controllingProjectsNoProjectsFound:
    "Für die ausgewählten Suchkriterien konnten keine Projekte gefunden werden",
  controllingProjectsCopyNumberTooltip:
    "Zum Kopieren der Projektnummer klicken.",
  controllingProjectsCopyNumberToast:
    "Die Projektnummer wurde in die Zwischenablage kopiert.",
  controllingProjectsProject: "Baustelle",
  controllingProjectsProjectLong: "Baustelle",
  controllingProjectsProjectGlossaryText: null,
  controllingProjectsStatus: "Status",
  controllingProjectsStatusLong: "Status",
  controllingProjectsStatusGlossaryText: null,
  controllingProjectsLastOutgoingInvoiceAt: "Letz. Rechn.",
  controllingProjectsLastOutgoingInvoiceAtLong: "Letzte Rechnung",
  controllingProjectsLastOutgoingInvoiceAtGlossaryText: null,
  controllingProjectsLastIncomingInvoiceAt: "Letz. Buch.",
  controllingProjectsLastIncomingInvoiceAtLong: "Letzte Buchung",
  controllingProjectsLastIncomingInvoiceAtGlossaryText: null,
  controllingProjectsLastInvoicesAtInaccurateWarning:
    "Beachte, dass bei gesetztem Buchungszeitraum-Filter das Datum der letzten Buchung und letzten Rechnung entsprechend dem Zeitraum angepasst wird. Es kann Buchungen und Rechnungen vor bzw. nach dem ausgewählten Zeitraum geben.",
  controllingProjectStatus_completed: "Abgeschlossen",
  controllingProjectStatus_in_progress: "In Bearbeitung",
  controllingProjectStatus_unstarted: "Bevorstehend",
  controllingProjectStatus_unknown: "Unbekannt",
  controllingProjectsProductiveHours: "Prod.-Std.",
  controllingProjectsProductiveHoursLong: "Produktivstunden",
  controllingProjectsProductiveHoursGlossaryText: null,
  controllingProjectsProfitContributionPerHourTotal: "DB / Std.",
  controllingProjectsProfitContributionPerHourTotalLong:
    "Deckungsbeitrag pro Stunde",
  controllingProjectsProfitContributionPerHourTotalGlossaryText: null,
  controllingProjectsMinimumInvoiceAmountTotal: "MAB",
  controllingProjectsMinimumInvoiceAmountTotalLong: "Mindestabrechnungsbetrag",
  controllingProjectsMinimumInvoiceAmountTotalGlossaryText: null,
  controllingProjectsMinimumInvoiceAmountTotalInaccurateWarning:
    "Beachte, dass bei gesetztem Buchungszeitraum-Filter die Herstellkosten und die Produktivstunden entsprechend dem Zeitraum angepasst werden. Daher kann sich auch der MAB verändern, da zur Berechnung die Daten entsprechend dem Buchungszeitraum-Filter herangezogen werden.",
  controllingProjectsCosts: "Herstellkosten",
  controllingProjectsCostsLong: "Herstellkosten",
  controllingProjectsCostsGlossaryText: null,
  controllingProjectsRevenue: "Erlöse & erh. Anz.",
  controllingProjectsRevenueLong: "Erlöse und erhaltene Anzahlungen",
  controllingProjectsRevenueGlossaryText: null,
  controllingProjectsProfitContribution: "DB (abs.)",
  controllingProjectsProfitContributionLong: "Gesamter Deckungsbeitrag",
  controllingProjectsProfitContributionGlossaryText: null,
  controllingProjectsProfitContributionPercentage: "DB (rel.)",
  controllingProjectsProfitContributionPercentageLong:
    "Relativer Deckungsbeitrag",
  controllingProjectsProfitContributionPercentageGlossaryText: null,
  controllingProjectDetailsTotalActual: "Ist",
  controllingProjectDetailsTotalTarget: "Gesamt-Soll",
  controllingProjectDetailsDelta: "Gesamt-Delta",
  controllingProjectDetailsSums: "Summen",
  controllingProjectDetailsMonths: "Monate",
  controllingProjectDetailsInaccurateWarning:
    "Beachte, dass bei gesetztem Buchungszeitraums-Filter die Soll-Zahlen nicht entsprechend errechnet werden können. Die Zahlen beziehen sich ausschließlich auf den gesamten Buchungszeitraum.",
  controllingProjectDetailsProfitContribution: "Deckungsbeitrag",
  controllingProjectDetailsProfitContributionLong: null,
  controllingProjectDetailsProfitContributionGlossaryText: null,
  controllingProjectDetailsRevenue: "Erlöse + erh. Anzahlungen",
  controllingProjectDetailsRevenueLong: null,
  controllingProjectDetailsRevenueGlossaryText: null,
  controllingProjectDetailsCostsTotal: "Kosten",
  controllingProjectDetailsCostsTotalLong: null,
  controllingProjectDetailsCostsTotalGlossaryText: null,
  controllingProjectDetailsCostsWages: "Lohn",
  controllingProjectDetailsCostsWagesLong: null,
  controllingProjectDetailsCostsWagesGlossaryText: null,
  controllingProjectDetailsCostsTools: "Gerät",
  controllingProjectDetailsCostsToolsLong: null,
  controllingProjectDetailsCostsToolsGlossaryText: null,
  controllingProjectDetailsCostsMaterials: "Material",
  controllingProjectDetailsCostsMaterialsLong: null,
  controllingProjectDetailsCostsMaterialsGlossaryText: null,
  controllingProjectDetailsCostsMisc: "Sonstiges",
  controllingProjectDetailsCostsMiscLong: null,
  controllingProjectDetailsCostsMiscGlossaryText: null,
  controllingProjectDetailsCostsPlants: "Pflanzen",
  controllingProjectDetailsCostsPlantsLong: null,
  controllingProjectDetailsCostsPlantsGlossaryText: null,
  controllingProjectDetailsCostsForeign: "Fremd",
  controllingProjectDetailsCostsForeignLong: null,
  controllingProjectDetailsCostsForeignGlossaryText: null,
  controllingProjectDetailsCostsDisposal: "Entsorgung",
  controllingProjectDetailsCostsDisposalLong: null,
  controllingProjectDetailsCostsDisposalGlossaryText: null,
  controllingProjectDetailsCostsOverheads: "BGK",
  controllingProjectDetailsCostsOverheadsLong: "Baustellengemeinkosten",
  controllingProjectDetailsCostsOverheadsGlossaryText:
    "Grundsätzlich sind die Baustellengemeinkosten, die Kosten einer Baustelle, die keiner Position zuzuordnen sind. In unserer Darstellung des Baubuches hier sind die Baustellengemeinkosten aktuell nur die Kosten, die durch Wegezeit entstanden sind.",
  controllingProjectEntriesModalHeaderText: "Buchungstext",
  controllingProjectEntriesModalHeaderJournalEntryDate: "Belegdatum",
  controllingProjectEntriesModalHeaderNumber: "Belegnummer",
  controllingProjectEntriesModalHeaderCustomer: "Kunde/Lieferant",
  controllingProjectEntriesModalHeaderCost: "Aufwand",
  controllingProjectEntriesModalHeaderRevenue: "Erlös",
  controllingProjectEntriesModalHeaderEmployee: "Arbeitnehmer",
  controllingProjectEntriesModalHeaderWageType: "Lohnart",
  controllingProjectEntriesModalHeaderWageEntryDate: "Datum",
  controllingProjectEntriesModalHeaderHours: "Stunden",
  controllingProjectEntriesModalHeaderWageCosts: "Lohn",
  controllingProjectsUnknownEmployee: "Unbekannt",
  controllingProjectsFooterTotal: "Gesamt",
  controllingProjectsFooterProjects: "Projekte",
  controllingProjectMetaInfoDialogTitle: "Baustelle {projectNumber}",
  controllingProjectMetaInfoInformationSectionTitle: "Informationen",
  controllingProjectMetaInfoDescriptionLabel: "Beschreibung",
  controllingProjectMetaInfoClientsLabel: "Auftraggeber",
  controllingProjectMetaInfoBusinessLineLabel: "Geschäftsfeld",
  controllingProjectMetaInfoServiceCategoryOrderManagementLabel:
    "Leistungskategorien (Auftragsverwaltung)",
  controllingProjectMetaInfoServiceCategoriesLabel:
    "Leistungskategorien (Arbeitspakete)",
  controllingProjectMetaInfoSuppliersLabel: "Lieferanten",
  controllingProjectMetaInfoEmployeesLabel:
    "Beteiligte gewerbliche Mitarbeiter",
  controllingProjectMetaInfoUnknownEmployee:
    "Unbekannter Mitarbeiter: {staffNumber}",
  controllingHoursEvaluations: "Stundenauswertung",
  controllingHoursEvaluationsDescription: "Stundenauswertung",
  controllingHourEvaluationsPageHeader: "Stundenauswertung",
  controllingHourEvaluationsChargeableHours: "Produktiv",
  controllingHourEvaluationsPreparation: "Lade- und Rüstzeit",
  controllingHourEvaluationsCommuting: "Wegezeit",
  controllingHourEvaluationsVacation: "Urlaub",
  controllingHourEvaluationsIllness: "Krankheit",
  controllingHourEvaluationsTraining: "Weiterbildung",
  controllingHourEvaluationsAdministration: "Administration",
  controllingHourEvaluationsLeisureTime: "Freizeitkonto",
  controllingHourEvaluationsBadWeather: "Schlechtwetter",
  controllingHourEvaluationsWageTypeTotal: "Gesamt",
  controllingHourEvaluationsWageTypeTotalTooltip: "Enthält alle Lohnarten",
  controllingHourEvaluationsProductiveHours: "Produktivzeit",
  controllingHourEvaluationsProductiveHoursTooltip:
    "Enthält die Lohnarten Produktiv und Lade- und Rüstzeit",
  controllingHourEvaluationsProductiveWithCommutingHours:
    "Produktivzeit zzgl. Wegezeit",
  controllingHourEvaluationsProductiveToTotalHoursRatio:
    "Verhältnis Produktivzeit zu Gesamt",
  controllingHourEvaluationsCommutingToTotalHoursRatio:
    "Verhältnis Wegezeit zu Gesamt",
  controllingHourEvaluationsIllnessToTotalHoursRatio:
    "Verhältnis Krankheit zu Gesamt",
  controllingHourEvaluationsTrainingToTotalHoursRatio:
    "Verhältnis Weiterbildung zu Gesamt",
  controllingHourEvaluationsBankHolidays: "Feiertage",
  controllingHourEvaluationsTotal: "Gesamt",
  controllingHourEvaluationsTotalUntilCurrentMonth:
    "Gesamt bis zum aktuellen Monat",
  controllingHourEvaluationsTarget: "Plan",
  controllingHourEvaluationsActual: "Ist",
  controllingHourEvaluationsPreviousMonth: "Vorheriger Monat",
  controllingHourEvaluationsNextMonth: "Nächster Monat",
  controllingHourEvaluationsNoEmployeesHourEvaluations:
    "Für diesen Stundentyp liegen keine Mitarbeiterstunden vor.",
  controllingHourEvaluationsGroupingSelectLabel: "Anzeigen nach...",
  controllingHourEvaluationsGroupingSelectBusinessLine:
    "Lohnart > Geschäftsfeld > Mitarbeitende",
  controllingHourEvaluationsGroupingSelectEmployee:
    "Lohnart > Mitarbeitende > Geschäftsfeld",
  surcharges: "Zuschlagssätze",
  surchargesDescription: "Zuschlagssätze bearbeiten",
  surchargesBreadcrumbs: "Zuschlagssätze",
  surchargesServiceCategoryDefault: "Default",
  surchargesUploadButton: "Datei hochladen",
  surchargesUploadSuccess: "Neue Zuschläge gespeichert",
  surchargesUploadFailed: "Fehler beim Speichern der Zuschläge",
  bookedHours: "Stunden aus der Lohnbuchhaltung",
  bookedHoursDescription: "Überblicke gebuchte Stunden deiner Mitarbeitenden",
  bookedHoursDateFilterLabel: "Buchungszeitraum",
  bookedHoursNothingToSeeTitle: "Keine Mitarbeitenden",
  bookedHoursNothingToSeeContent:
    "Dem ausgewählten Bauleitenden sind keine Mitarbeitenden zugeordnet.",
  bookedHoursTooltipBookedHoursLabel: "Stunden Lohnbuchhaltung",
  bookedHoursTooltipTrackedHoursLabel: "Stunden grewe-gruppe App",
  bookedHoursTooltipMaxWorkingHoursFullAge:
    "Maximale Tagesarbeitszeit von 10 Stunden überschritten",
  bookedHoursTooltipMaxWorkingHoursUnderage:
    "Maximale Tagesarbeitszeit von 8 Stunden für Minderjährige überschritten",
  bookedHoursTooltipMaxWeeklyWorkingHoursFullAge:
    "Maximale Wochenarbeitszeit von 48 Stunden überschritten",
  bookedHoursTooltipMaxWeeklyWorkingHoursUnderage:
    "Maximale Wochenarbeitszeit von 40 Stunden für Minderjährige überschritten",
  bookedHoursDifferenceBetweenTrackedAndBookedHours:
    "Abweichung zw. erfasster und gebuchter Arbeitszeit",
  bookedHoursMaxWorkingHoursExceededIconLabel:
    "Überschreitung der maximalen Arbeitszeit",
  bookedHoursOverheadCosts: "Gemeinkosten",
  employeeAdministration: "Mitarbeitendenverwaltung",
  employeeAdministrationDescription:
    "Übersicht und Verwaltung der Mitarbeitenden",
  employeeAdministrationFilterSelectLabel: "Zeige",
  employeeAdministrationSuperiorSelectOptionAll: "Alle",
  employeeAdministrationSuperiorSelectOptionAssignmentPending:
    "Zuordnung ausstehend",
  employeeAdministrationSuperiorAssignmentMissingSingular:
    "Zuordnung ausstehend",
  employeeAdministrationSuperiorAssignmentMissingPlural:
    "Zuordnungen ausstehend",
  employeeAdministrationEditEmployeeNotPermittedTooltip:
    "Du bist nicht berechtigt Mitarbeiter zu bearbeiten. Bitte wende dich an den Geschäftsführer",
  employeeAdministrationExportPurchasingButtonLabel: "Export Einkauf",
  employeeAdministrationExportControllingButtonLabel: "Export Controlling",
  employeeAdministrationFilterSelectOptionAmbiguousAddresses:
    "Uneindeutige Adresse",
  employeeAdministrationAmbiguousAddresses: "Uneindeutige Adressen",
  employeeAdministrationAmbiguousAddress: "Uneindeutige Adresse",
  employeeListNameLabel: "Name",
  employeeListJobTitleLabel: "Stellenbezeichnung",
  employeeListSuperiorLabel: "Vorgesetzte/r",
  employeeListResidenceAddressLabel: "Adresse",
  employeeListResidenceAddressStatusLabel: "Adress-Status",
  employeeListToolsCostCenterLabel: "Gerätekostenbaustelle",
  employeeListOfficeLocationLabel: "Standort",
  employeeListTeamsLabel: "Kolonnen",
  employeeListActionsLabel: "Aktionen",
  employeeListNoTeam: "keine",
  employeeListNoJobTitle: "Stellenbezeichnung nicht gesetzt",
  employeeListSuperiorMissing: "Zuordnung ausstehend",
  employeeListAddressStatusHigh: "✔ Eindeutig",
  employeeListAddressStatusManual: "Präzisiert",
  employeeListAddressStatusLow: "✗ Uneindeutig",
  employeeJobTitleManagment: "Geschäftsführung",
  editEmployeeDialogTitle: "Mitarbeitenden bearbeiten",
  editEmployeeSuperiorLabel: "Vorgesetzte/r",
  editEmployeeSuperiorNoOptions: "Keine Vorgesetzte/r gefunden",
  editEmployeeCostCenterLabel: "Gerätekostenbaustelle",
  editEmployeeSuccessFeedback: "Mitarbeiter/in wurde gespeichert",
  employeeValidationTeamsMustBelongToSameTenant:
    "Kolonnen müssen zum gleichen Mandanten gehören",
  employeeValidationSuperiorOfManagerMustBeAHoldingManager:
    "Vorgesetzte/r muss auch Geschäftsführung sein",
  employeeValidationSuperiorOfNonManagerMustBeSameTenant:
    "Vorgesetzte/r muss zum gleichen Mandanten gehören",
  employeeValidationSuperiorCannotBeSelf:
    "Vorgesetzte/r darf nicht man selbst sein",
  employeeValidationSuperiorCannotBeCircular:
    "Vorgesetzte/r darf nicht zirkulär sein",
  toolAbbreviationLabel: "Kürzel",
  toolAbbreviationPrefixLabel: "Kürzel-Präfix",
  toolAttachmentsLabel: "Anbauteile",
  toolBillableFromLabel: "Berechnet ab",
  toolCategoryLabel: "Kategorie",
  toolCommentLabel: "Bemerkung",
  toolConstructionYearLabel: "Baujahr",
  toolCostCenterLabel: "Kostenstelle",
  toolDailyRateLabel: "Tagessatz",
  toolDecommissionButton: "Stilllegen",
  toolDecommissionCommentLabel: "Kommentar zur Stilllegung",
  toolDecommissionReason: "Begründung",
  toolDecommissionReasonInventoryShrinkage: "Inventurschwund",
  toolDecommissionReasonLabel: "Begründung der Stilllegung",
  toolDecommissionReasonSale: "Verkauf",
  toolDecommissionReasonScrap: "Schrott",
  toolDecommissionReasonTheft: "Diebstahl",
  toolDecommissionReasonUnknown: "Unbekannt",
  toolDecommissionReasonReturnAfterLeasing: "Rückgabe nach Leasing",
  toolDecommissionReasonLongTermRentalEnded: "Dauermiete beendet",
  toolDecommissionReasonError: "Fehler",
  toolDecommissionedAtLabel: "Datum der Stilllegung",
  toolDecommissioning: "Stilllegung",
  toolDecommissioningModalCommentLabel: "Kommentar",
  toolDecommissioningModalSubmit: "Stilllegen",
  toolDecommissioningModalTitle: "Gerät endgültig stilllegen",
  toolDecommissioningScheduledSuccessToast:
    "Stilllegung für Gerät {abbr} erfolgreich terminiert.",
  toolDecommissioningSuccessToast:
    "Gerät {abbr} erfolgreich endgültig stillgelegt.",
  toolDetailsButton: "Details anzeigen",
  toolDuplicateButton: "Duplizieren",
  toolFilterAllTools: "Alle Geräte",
  toolFilterManagersLabel: "Manager",
  toolFilterMyResponsibilities: "Ich",
  toolFilterMyTools: "Meine Geräte",
  toolFilterTenantsLabel: "Mandanten",
  toolFilterWithoutResponsibleEmployee: "Niemand",
  toolFinancingToLabel: "Finanzierung bis",
  toolFinancingTypeLabel: "Finanzierungsstatus",
  toolFormAbbreviationConflict:
    "Dieses Kürzel ist bereits vergeben oder das Schema ist inkorrekt.",
  toolFormBasicInfoHeadline: "Grunddaten",
  toolFormBothOrNoneError:
    "Bitte beide Felder ausfüllen oder beide leer lassen",
  toolFormConstructionYearError: "Bitte ein gültiges Baujahr eingeben",
  toolFormCoreDataTitle: "Stammdaten",
  toolFormEditHeadline: "{name} bearbeiten",
  toolFormMiscDataTitle: "Sonstige Daten",
  toolNameLabel: "Bezeichnung",
  toolFormPurchaseDataTitle: "Kaufdaten",
  toolFormSerialNumberDuplicateError:
    "Die Seriennummber {serialNumber} existiert bereits in den Geräten {tools}. Bist du sicher, dass du diese verwenden möchtest?",
  toolFormToolIdConflict: "Diese Geräte-ID ist bereits vergeben.",
  toolHistoryButton: "Historie anzeigen",
  toolHistoryChangesNo: "Nein",
  toolHistoryChangesYes: "Ja",
  toolHistoryModalTableHeaderDate: "Am",
  toolHistoryModalTableHeaderDescription: "Beschreibung",
  toolHistoryModalTableHeaderModifiedBy: "Geändert durch",
  toolHistoryModalTableHeaderTime: "Um",
  toolHistoryModalTableHeaderType: "Vorgang",
  toolHistoryModalTitle: "Gerätehistorie",
  toolHistoryToolWasCreated: "Das Gerät wurde erstellt",
  toolHistoryTypeChipCreated: "Erstellung",
  toolHistoryTypeChipResponsibilityReturned: "Rückgabe",
  toolHistoryTypeChipResponsibilityTaken: "Übernahme",
  toolHistoryTypeChipUpdated: "Datenänderung",
  toolHistoryValueChangedFrom: 'geändert von "{old}" zu ---',
  toolHistoryValueChangedFromTo: 'geändert von "{old}" zu "{new}"',
  toolHistoryValueChangedTo: 'geändert zu "{new}"',
  toolIdLabel: "ID",
  toolInsuranceLineLabel: "Versicherungssparte",
  toolInsuranceToolTypeLabel: "Fahrzeug- / Anlagenart für Versicherung",
  toolInsuranceTypeLabel: "versicherter Tarif",
  toolIsBillableLabel: "Leistungsgerät",
  toolIsNotBillableLabel: "Gemeinkostengerät",
  toolLicensePlateNumberLabel: "Kfz-Kennzeichen",
  toolListActionsLabel: "Aktionen",
  toolListIsBillable: "Leistung",
  toolListIsNotBillable: "Gemeinkosten",
  toolListPreviousResponsibleEmployee: "Zuletzt",
  toolListSerialNumber: "SN:",
  toolListTenantLabel: "Firma",
  toolListToolId: "ID",
  toolListTypeLabel: "Geräteart",
  toolManagement: "Geräteverwaltung",
  toolManagementDescription: "Verantworte deine Geräte.",
  toolManagerLabel: "Manager/in",
  toolManufacturerLabel: "Hersteller",
  toolMinuteRateLabel: "Minutensatz",
  toolOperatingHoursLabel: "Anzahl Betriebsstunden",
  toolPowerLabel: "Leistung",
  toolPurchaseDateLabel: "Kaufdatum",
  toolPurchasePriceLabel: "Anschaffungspreis (netto)",
  toolQueryOperatingHoursLabel: "Abfrage Betriebsstunden",
  toolRecommissionButton: "Reaktivieren",
  toolRecommissioningModalSubmit: "Reaktivieren",
  toolRecommissioningModalTitle: "Gerät reaktivieren",
  toolRecommissioningSuccessToast: "Gerät {abbr} erfolgreich reaktiviert.",
  toolResponsibleEmployeeLabel: "Verantwortliche/r",
  toolReturnResponsibility: "Zurückgeben",
  toolReturnResponsibilitySuccess: "Gerät {abbr} zurückgegeben.",
  toolSerialNumberLabel: "Seriennummer",
  toolSizeLabel: "Größe",
  toolSupplierLabel: "Lieferant (extern)",
  toolTakeAndReturnResponsibilityHint:
    "Wird bei Zeitbuchungen automatisch übernommen o. zurückgegeben.",
  toolTakeResponsibility: "Übernehmen",
  toolTakeResponsibilitySuccess: "Gerät {abbr} übernommen.",
  toolTemporarilyDecommissionedFromLabel: "Saisonal stillgelegt von",
  toolTemporarilyDecommissionedToLabel: "Saisonal stillgelegt bis",
  toolTenantLabel: "Mandant",
  toolToolIdLabel: "Geräte-ID",
  toolToolTypeLabel: "Geräteart",
  toolToolsCostCenterLabel: "Gerätekostenbaustelle",
  toolTypeDesignationLabel: "Typenbezeichnung",
  toolsAndDevices: "Maschinen & Geräte",
  toolsCostCenters: "Gerätekostenbaustellen",
  toolsCostCentersDescription: "Ordne Managern Gerätekostenbaustellen zu.",
  toolsCostCentersSuccessToast: "Gerätekostenstelle wurde gespeichert",
  toolsCostCentersTableCostCenter: "Gerätekostenbaustelle",
  toolsCostCentersTableName: "Name",
  exportTools: "Geräteliste exportieren",
  exportToolAccountingRecords: "Gerätestunden exportieren",
  errorExportingTools:
    "Beim Exportieren der Geräteliste ist etwas schiefgelaufen. Bitte versuche es später erneut.",
  errorExportingToolAccountingRecords:
    "Beim Exportieren der Gerätestunden ist etwas schiefgelaufen. Bitte versuche es später erneut.",
  addTool: "Gerät anlegen",
  addToolSuccess: "Gerät {abbr} erfolgreich hinzugefügt",
  editToolSuccess: "Gerät {abbr} erfolgreich bearbeitet",
  teamAdministration: "Kolonnenverwaltung",
  teamAdministrationDescription: "Verwalte die Mitarbeitenden deiner Kolonnen.",
  monitoring: "Monitoring",
  monitoringDescription: "Beobachte aktive Zeitbuchungen.",
  timesAndTools: "Meine Zeiten und Geräte",
  tasks: "Meine Aufgaben",
  tasksEmpty: "So weit alles sauber. Weitermachen!",
  tasksTimeApprovalTitleHtml: `
    <strong>Zeitenbuchungen</strong> warten auf deine <strong>Freigabe</strong>
  `,
  tasksTimeApprovalLink: "Zur Zeitenfreigabe",
  tasksEmployeeAdministrationMissingSuperiorsTitleHtml: `
    <strong>Mitarbeitende</strong> wollen <strong>Vorgesetzte</strong>
  `,
  tasksEmployeeAdministrationAmbiguousAddressesTitleHtml: `
    <strong>Privatadressen</strong> müssen <strong>präzisiert</strong> werden
  `,
  tasksEmployeeAdministrationLink: "Zur Mitarbeitendenverwaltung",
  takePhoto: "Foto aufnehmen",
  timeTracking: "Zeiterfassung",
  timeTrackingDescription: "Erfasse deine Arbeitszeiten.",
  appName: "grewe-gruppe App",
  startPage: "Startseite",
  breadcrumb: "Breadcrumb",
  maintenance: "Instandhaltung",
  assignments: "Aufträge",
  workload: "Auslastung",
  columnView: "Spalten",
  listView: "Liste",
  add: "Hinzufügen",
  remove: "Entfernen",
  discard: "Verwerfen",
  edit: "Bearbeiten",
  save: "Speichern",
  saveAndContinue: "Speichern & weiter",
  cancel: "Abbrechen",
  back: "Zurück",
  saved: "Gespeichert",
  team: "Kolonne",
  noTeamAssignment: "Keine Kolonnenplanung...",
  teamAssignmentCollapseAll: "Alle Planungen einklappen",
  teamAssignmentExpandAll: "Alle Planungen ausklappen",
  teamAssignmentHeadingNewPlaceholder: "Neue Kolonnenplanung",
  teamAssignmentHeadingTeamPlaceholder: "Bitte Kolonne wählen...",
  teamAssignmentHeadingWorkPackagePlaceholder:
    "Bitte Arbeitspaket und Zeitraum wählen...",
  teamAssignmentHeadingCopyLabel: "Zum Kopieren der Planung klicken",
  teamAssignmentHeadingDeleteLabel: "Planung entfernen",
  teamAssignmentHeadingSerialDateLabel: "Serienplanung erstellen",
  teamAssignmentSerialDateDialogTitle: "Serienplanung erstellen",
  teamAssignmentSerialDateDialogDateRangeLabel: "Aus Zeitraum",
  teamAssignmentSerialDateDialogrepetitionIntervalLabel: "Wiederholen alle",
  teamAssignmentSerialDateDialogRepetitionWeekdaysLabel: "Wiederholen am",
  teamAssignmentSerialDateDialogEndHeader: "Ende",
  teamAssignmentSerialDateDialogEndOnYearEndRadioLabel: "Zum Jahresende",
  teamAssignmentSerialDateDialogEndOnDateRadioLabel: "Am",
  teamAssignmentSerialDateDialogEndAfterNumberOfDatesRadioLabel: "Nach",
  teamAssignmentSerialDateDialogEndAfterNumberOfDatesLabelSingular:
    "Wiederholung",
  teamAssignmentSerialDateDialogEndAfterNumberOfDatesLabelPlural:
    "Wiederholungen",
  teamAssignmentSerialDateDialogDoneButtonLabel: "Fertig",
  teamAssignmentSerialDateDialogMonthyTypeOptionDate: "Monatlich am {date}.",
  teamAssignmentSerialDateDialogMonthyTypeOptionDay:
    "Monatlich am {weekNumber}. {weekday}",
  teamAssignmentSerialDateDialogMonthyTypeOptionDayLastWeek:
    "Monatlich am letzten {weekday}",
  teamAssignmentSerialDateDialogEndOnDateMustBeAfterStartDateError:
    "Datum muss nach dem Startdatum des Zeitraums liegen",
  teamAssignmentSerialDateDialogSuccessToast:
    "Es wurden {count} Planungen angelegt.",
  teamAssignmentSerialDateDialogErrorToast:
    "Erstellung abgebrochen, da zu viele Planungen (mehr als {count}) erstellt werden würden.",
  coreTeam: "Kolonne",
  selectCoreTeam: "Kolonne wählen...",
  noCoreTeam: "Keine Kolonne gewählt...",
  additionalResources: "Zusätzliche Mitarbeiter o. Geräte",
  selectAdditionalResources: "Mitarbeiter o. Geräte suchen...",
  additionalEmployees: "Zusätzliche Mitarbeiter",
  selectAdditionalEmployees: "Mitarbeiter suchen...",
  saveTeam: "Kolonne speichern",
  manageTeam: "Kolonnenverwaltung",
  searchTeams: "Suche Kolonnen",
  teamPersonOrTool: "Kolonne, Person o. Gerät",
  workPackage: "Arbeitspaket",
  workPackageAdministration: "Arbeitspakete",
  workPackageAdministrationDescription: "Verwalte deine Arbeitspakete.",
  workPackageAdministrationColumnsWorkPackage: "Arbeitspaket",
  workPackageAdministrationColumnsManager: "Bauleiter",
  workPackageAdministrationColumnsServiceCategory: "Leistungskategorie",
  workPackageAdministrationColumnsWageType: "Lohnart",
  workPackageAdministrationColumnsLocation: "Ausführungsort",
  workPackageAdministrationColumnsNote: "Hinweis z. Ausf. an Kolonne",
  workPackageAdministrationColumnActions: "Aktionen",
  workPackageAdministrationConstructionSiteLink: "Zur Baustelle",
  workPackageAdministrationSearchPlaceholder:
    "Baustelle oder Arbeitspaket suchen...",
  workPackageAdministrationTabAll: "Alle ({count})",
  workPackageAdministrationTabInvalid: "Fehlerhafte ({count})",
  workPackageAccountingFieldsUnchangeableInfo:
    "Einige Felder können nicht verändert werden, da es bereits Zeitbuchungen für das Arbeitspaket gibt.",
  workPackageChargeableHoursOnlyFieldsDisabledInfo:
    "Einige Felder werden für die gewählte Lohnart nicht benötigt und sind daher nicht bearbeitbar.",
  workPackageValidationAccountingFieldsCannotBeModified:
    "Dieses Feld kann nicht verändert werden, da es bereits Zeitbuchungen für das Arbeitspaket gibt",
  workPackageValidationCommuteExpensesOnlyWithChargeableHours:
    "Wegegeld kann nur für Produktivstunden aktiviert werden",
  selectWorkPackage: "Arbeitspaket wählen",
  noWorkPackage: "Kein Arbeitspaket gewählt...",
  noWorkPackageHelperText:
    "Du musst ein Arbeitspaket wählen oder neu anlegen...",
  addWorkPackage: "Neues Arbeitspaket",
  addWorkPackageFromTextbox: "Neues Arbeitspaket {workPackage} anlegen",
  addWorkPackageSuccess: "Arbeitspaket erfolgreich erstellt",
  editWorkPackage: "Arbeitspaket bearbeiten",
  editWorkPackageCopyClientAddressTooltip:
    "Zum Kopieren der Auftraggeber-Adresse klicken.",
  editingWorkPackageCopyClientAddressSuccessToast:
    "Die Auftraggeber-Adresse wurde in die Zwischenablage kopiert.",
  workHoursPerDay: "Arbeitsstunden / Tag",
  numDays: "Anzahl Tage",
  from: "von",
  to: "bis",
  on: "am",
  dateRange: "Zeitraum",
  addTeamAssignmentBuilder: "Kolonnenplanung hinzufügen",
  invalidDate: "Ungültiges Datum.",
  invalidDateRange: "Start-Datum muss vor End-Datum liegen",
  invalidDateWithoutYear: "Ungültiges Datum. Bitte nutze das Format TT.MM.",
  invalidLicensePlateNumber: "Das Kennzeichen ist ungültig.",
  invalidLocation: "Die Adresse ist ungültig.",
  dateInPast: "Datum darf nicht in der Vergangenheit liegen.",
  dateInFuture: "Datum darf nicht in der Zukunft liegen.",
  numberBetween: "Bitte gib eine gültige Zahl ein.",
  required: "Pflichtfeld",
  optional: "optional",
  maxLength: "Maximale Länge: %{maxLength} Zeichen",
  noOptions: "Keine Einträge gefunden",
  trackingCurrent: "Heute geplante Arbeitspakete",
  constructionSiteManager: "Bauleiter",
  managerSelectAllOption: "Alle",
  timeEntryStatusOpen: "Offen",
  timeEntryStatusManagerAccount: "Bauleiterkonto",
  timeEntryStatusApproved: "Freigegeben",
  timeEntryStatusExported: "Gebucht",
  timeEntryStatusDeleted: "Gelöscht",
  timeEntryStatusConflict: "Konflikt",
  timeEntryStatusConflicts: "Konflikte",
  timeEntryCommuteAnomaly: "Auffällige Wegezeit",
  timeEntryCommuteAnomalies: "Auffällige Wegezeiten",
  constructionSiteListUnplanned: "Ungeplant",
  constructionSiteListUnplannedFortnight:
    "(Start innerhalb der nächsten 14 Tage oder geplanter Baubeginn überschritten)",
  constructionSiteListPlanned: "Geplant",
  constructionSiteListDone: "Abgeschlossen",
  constructionSiteListInProgress: "In Bearbeitung",
  constructionSiteListUnplannedWarning: "Geplanter Baubeginn unbekannt",
  trackingAlreadyStoppedTitle: "Buchung bereits beendet",
  trackingAlreadyStoppedDescription:
    "Deine Buchung wurde bereits beendet. Dies kann z. B. durch den Bauleiter geschehen.",
  trackingAlreadyStoppedButton: "Verstanden & Weiter",
  trackingConstructionSiteManager: "Mein Bauleiter",
  trackingConstructionSiteManagerAccount: "Bauleiterkonto",
  trackingConstructionSiteManagerAccountInfo:
    "Erfasse deine Zeiten auf dieses Bauleiterkonto, wenn noch kein Arbeitspaket für deine Baustelle existiert.",
  trackingConstructionSiteManagerDialogTitle: "Auftrag erfassen",
  trackingConstructionSiteManagerFormCustomerName:
    "Kundenname / Ansprechpartner",
  trackingConstructionSiteManagerFormManagerName: "Bauleiter",
  trackingConstructionSiteManagerFormStreet: "Straße",
  trackingConstructionSiteManagerFormHouseNumber: "Haus-Nr.",
  trackingConstructionSiteManagerFormZip: "PLZ",
  trackingConstructionSiteManagerFormCity: "Ort",
  trackingConstructionSiteManagerFormPhone: "Telefonnummer",
  trackingConstructionSiteManagerFormDescription:
    "Kundenauftrag / Beschreibung der Baustelle",
  trackingConstructionSiteManagerTeamName: "Buchung auf Bauleiterkonto",
  trackingSummaryModalTitle: "Zusammenfassung",
  trackingTimeEntrySummaryModalTitle: "Erfasste Zeiten",
  trackingTimeEntrySummaryTableNet: "Netto",
  trackingSummaryModalPerformedActivities: "Durchgeführte Tätigkeiten",
  trackingSummaryModalNote: "Notiz",
  trackingSummaryModalContinueButton: "Zurück",
  trackingSummaryModalStopButton: "Beenden",
  trackingSplitModalTitle: "Zeitbuchung aufteilen",
  trackingSplitModalDescription: `Deine Zeitbuchung hat Mitternacht überschritten. Aus technischen Gründen
  muss deine laufende Zeitbuchung in zwei tagesbezogene Buchungen
  aufgeteilt werden.
    <br />
    Deine laufende Buchung zählt die Zeit seit 00:00 Uhr, deine gestrigen
  Zeiten bis 23:59 Uhr sind wie folgt zusammengefasst:`,
  trackingAutoStopSuccessToast:
    "Buchung wurde automatisch beendet. Buchungen werden nach 16 Stunden automatisch beendet.",
  trackingSplitModalConfirm: "Weitermachen!",
  trackingForceStopModalTitle: "Zeitbuchung stoppen",
  trackingForceStopModalDescription: `Die Buchungen werden gestoppt und die Laufzeit auf 0 Sekunden gesetzt.
  Weitere Anpassungen der Buchung kannst du nach Beendigung im Bereich Zeitenfreigabe vornehmen.`,
  trackingForceStopModalConfirm: "Zeitbuchung stoppen",
  trackingSwitchWorkPackageDialogTitle: "Arbeitspaket wechseln",
  trackingSwitchWorkPackageDialogContent:
    "Bei Bestätigen werden alle zusätzlichen Mitarbeitenden und Geräte aus der aktuellen Zeitbuchung ausgebucht.",
  trackingSwitchWorkPackageDialogConfirmButton: "Bestätigen",
  trackingSwitchNotPossibleError:
    "Ein direkter Wechsel der Zeiterfassung ist leider nicht möglich. Bitte stoppe zuerst die laufende Zeiterfassung.",
  trackingOverviewTrainingTitle: "Weiterbildung",
  trackingOverviewTrainingInfo: "Hier klicken, um Weiterbildung einzutragen",
  trackingTrainingBannerTitle: "Weiterbildung",
  trackingUnknownBannerTitle: "Unbekannte Aufgabe",
  trackingTrainingParticipants: "Teilnehmende",
  trackingTrainingModalTitle: "Weiterbildungsdetails erfassen",
  trackingTrainingFormApprovalBy: "Freizugeben von",
  trackingTrainingFormNote: "Bemerkung",
  dailySummaryBreadcrumbsHeader: "Heutige Buchungen",
  dailySummaryTableHeading: "Gesamt",
  dailySummaryTableName: "Name",
  dailySummaryTableTime: "Zeit",
  dailySummaryTableBreak: "Pause",
  dailySummaryTableNet: "Netto",
  dailySummaryTableError:
    "Zeitenzusammenfassung kann nicht angezeigt werden, da es Fehler in den Zeiten gibt.",
  dailySummaryTableNoTimeTrackings:
    "Du hast heute noch keine Buchungen abgeschlossen.",
  dailySummaryAccordionEntriesFrom: "Buchungen von",
  dailySummaryBack: "Zurück",
  trackingRecent: "Zuletzt benutzt",
  startTimeTracking: "Zeiterfassung starten",
  startTimeTrackingSuccess: "Zeiterfassung gestartet",
  splitTrackingSuccess:
    "Deine Zeiterfassung wurde geteilt. Wir zeigen dir jetzt die Dauer ab der Teilung an. Keine Sorge, deine bisherige Buchung ist sicher.",
  stopTimeTracking: "Stoppen",
  stopTimeTrackingSuccess: "Zeiterfassung gestoppt",
  timeTrackingCommuteDialogStartTitle: "Hinweg bestimmen",
  timeTrackingCommuteDialogStartStepOneTitle: "Woher kommt ihr heute?",
  timeTrackingCommuteDialogStartStepTwoTitle: "Wer ist gefahren?",
  timeTrackingCommuteDialogStartLocationTitle: "Abfahrtsort",
  timeTrackingCommuteDialogStopTitle: "Rückweg bestimmen",
  timeTrackingCommuteDialogStopStepOneTitle: "Rückweg wohin?",
  timeTrackingCommuteDialogStopStepTwoTitle: "Wer fährt zurück?",
  timeTrackingCommuteDialogStopLocationTitle: "Ziel",
  timeTrackingCommuteDialogStepCounter: "Schritt {current} von {total}",
  timeTrackingCommuteDialogHomeIconTitle: "Zuhause",
  timeTrackingCommuteDialogSiteIconTitle: "Betriebshof",
  timeTrackingCommuteDialogNoCommuteIconTitle: "Kein Wegegeld",
  timeTrackingCommuteDialogDriverIconTitle: "Fahrer/in",
  timeTrackingCommuteDialogNextButton: "Weiter",
  timeTrackingCommuteDialogBackButton: "Zurück",
  timeTrackingCommuteDialogCancelButton: "Abbrechen",
  timeTrackingCommuteDialogStartTrackingButton: "Fertig",
  timeTrackingCommuteDialogStopTrackingButton: "Beenden",
  timeTrackingQuickCheckInCheckOutButtonLabel_arrival_no_commute:
    "Kein Wegegeld",
  timeTrackingQuickCheckInCheckOutButtonLabel_arrival_driver_home:
    "<b>Von Zuhause</b> als <b>Fahrer/in</b>",
  timeTrackingQuickCheckInCheckOutButtonLabel_arrival_driver_site:
    "<b>Vom Betriebshof</b> als <b>Fahrer/in</b>",
  timeTrackingQuickCheckInCheckOutButtonLabel_arrival_passenger_home:
    "<b>Von Zuhause</b> als <b>Beifahrer/in</b>",
  timeTrackingQuickCheckInCheckOutButtonLabel_arrival_passenger_site:
    "<b>Vom Betriebshof</b> als <b>Beifahrer/in</b>",
  timeTrackingQuickCheckInCheckOutButtonLabel_departure_no_commute:
    "Kein Wegegeld",
  timeTrackingQuickCheckInCheckOutButtonLabel_departure_driver_home:
    "<b>Nach Hause</b> als <b>Fahrer/in</b>",
  timeTrackingQuickCheckInCheckOutButtonLabel_departure_driver_site:
    "<b>Zum Betriebshof</b> als <b>Fahrer/in</b>",
  timeTrackingQuickCheckInCheckOutButtonLabel_departure_passenger_home:
    "<b>Nach Hause</b> als <b>Beifahrer/in</b>",
  timeTrackingQuickCheckInCheckOutButtonLabel_departure_passenger_site:
    "<b>Zum Betriebshof</b> als <b>Beifahrer/in</b>",
  timeTrackingSince: "seit",
  addResource: "{resource} hinzufügen",
  editResource: "{resource} bearbeiten",
  removeResource: "{resource} entfernen",
  open: "Öffnen",
  close: "Schließen",
  clear: "Zurücksetzen",
  signIn: "Mit Microsoft anmelden",
  signOut: "Abmelden",
  syncButtonTooltip: "Daten der App synchronisieren",
  syncButtonConstructionSitesTooltip: "Baustellen-Daten synchronisieren",
  syncButtonEmployeesTooltip: "Mitarbeitenden-Daten synchronisieren",
  syncButtonControllingDataTooltip: "Controlling-Daten synchronisieren",
  syncButtonAppDataTooltip: "App-Daten synchronisieren",
  syncMenuTitle: "Synchronisations-Status",
  syncMenuEmployeesTitle: "Mitarbeitenden-Daten",
  syncMenuConstructionSitesTitle: "Baustellen-Daten",
  syncMenuControllingTitle: "Controlling-Daten",
  syncMenuAppDataTitle: "App-Daten",
  syncMenuLastSyncedAt: "Zuletzt synchronisiert:",
  syncMenuLastSyncUnknown: "Synchronisations-Status unbekannt",
  syncMenuMaxTimeInformation: "Dauert bis zu {maxTime} min...",
  syncMenuSyncRunning: "Synchronisation im Gange",
  syncMenuSyncFailed: "Letzte Synchronisation fehlgeschlagen",
  syncMenuFooter: "Bei Fehlern wende Dich bitte per Mail an",
  syncRefetchSuccess: "Synchronisierung abgeschlossen",
  notFoundHeader: "Seite nicht gefunden",
  notFoundText: "Diese Seite konnte nicht gefunden werden.",
  genericErrorPageHeader: "Ein Fehler ist aufgetreten",
  genericErrorPageText:
    "Bitte lade die Seite neu. Wir haben bereits eine Fehlermeldung im Hintergrund generiert. Tritt der Fehler erneut auf, wende Dich bitte an app@grewe-gruppe.de.",
  forbiddenHeader: "Fehlende Berechtigungen",
  forbiddenText: "Du hast keine Zugriffsberechtigung.",
  forbiddenFooter:
    "Wenn du glaubst, dass das nicht so sein sollte, melde dich bitte bei deinem Administratoren-Team.",
  forbiddenContacts: "Bea Timmermann, Dominik Elsbroek",
  tenantForbiddenHeader: "Fehlende Berechtigungen für {forbiddenTenantName}",
  tenantForbiddenText:
    "Aktuell fehlen dir die Berechtigungen, auf die Inhalte von <strong>{forbiddenTenantName}</strong> zuzugreifen.",
  tenantForbiddenLink: "Weiter zu {allowedTenantName}",
  geolocationError:
    "Bitte gib den Zugriff auf den Standort frei, um diese Funktion zu nutzen",
  teams: "Kolonnen",
  editTeam: "Kolonne bearbeiten",
  editSuccess: "Änderungen erfolgreich gespeichert",
  addTeam: "Kolonne hinzufügen",
  addTeamSuccess: "Kolonne erfolgreich hinzugefügt",
  deleteTeamSuccess: "Kolonne erfolgreich gelöscht",
  teamFormName: "Name",
  teamFormCancel: "Abbrechen",
  teamFormSave: "Speichern",
  teamFormDelete: "Löschen",
  teamConstructionSiteManagerLabel: "Bauleiter",
  teamConstructionSiteManagerError: "Bitte wähle einen Bauleiter.",
  teamFormItemMultiSelectorLabel: "Mitarbeiter o. Geräte suchen...",
  teamFormItemMultiSelectorError:
    "Bitte wähle mindestens einen Mitarbeiter oder ein Gerät.",
  unknownError:
    "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es nochmal. Wir haben bereits eine Fehlermeldung im Hintergrund generiert. Tritt der Fehler erneut auf, wende Dich bitte an app@grewe-gruppe.de.",
  unknownEmployee: "Unbekannter Mitarbeiter",
  unknownTenant: "Unbekannter Mandant",
  unknownCategory: "Unbekannte Kategorie",
  unknownCategoryTooltip: "Kategorie nicht gefunden",
  unknownConstructionSiteManager: "Unbekannter Bauleiter",
  unknownWorkPackage: "Unbekanntes Arbeitspaket",
  unknownConstructionSite: "Unbekannte Baustelle",
  "name has already been taken": "Der Name ist bereits vergeben.",
  lv_identifier_taken: "Identifier bereits vergeben.",
  teamFormCurrentlyPartOf: "Ist aktuell Teil der Kolonne",
  teamFormAddPermanently: "Aus aktueller Kolonne dauerhaft übernehmen",
  teamFormAddAll: "Alle übernehmen",
  teamFormAllAdded: "Alle Änderungen übernommen",
  collapseRow: "Reihe einklappen",
  expandRow: "Reihe ausklappen",
  teamlessResources: "Mitarbeiter und Geräte ohne Kolonne",
  teamless: "Keiner Kolonne zugeordnet",
  tool: "Maschine",
  tools: "Maschinen",
  taskCustomer: "Kunde",
  taskDescription: "Kurzbeschreibung",
  taskPerformedActivities: "Durchgeführte Tätigkeiten",
  taskNote: "Notiz",
  name: "Name",
  editedAt: "Letzte Änderung",
  updateAvailable:
    "Es ist ein Update verfügbar! Bitte klicke auf Aktualisieren um sicherzustellen, dass die Anwendung weiterhin funktioniert.",
  updateNow: "Aktualisieren",
  invalidConstructionSites: "Fehlerhafte LVs",
  invalidConstructionSitesDescription:
    "Finde zu korrigierende Leistungsverträge.",
  invalidProjects: "Fehlerhafte Projekte",
  invalidProjectsDescription: "Finde zu korrigierende Projekte.",
  invalidProjectsInvalidTenantNumberReason:
    "Projekt hat eine ungültige Projektnummer.",
  invalidProjectsUnknownTenantNumberReason:
    "Projekt hat einen unbekannten Mandanten.",
  invalidProjectsUnknownReason:
    "Projekt ist ungültig. Der Grund '%{reason}' ist unbekannt.",
  invalidTools: "Fehlerhafte Geräte",
  invalidToolsDescription: "Finde zu korrigierende Gerätedaten.",
  invalidToolsNameMissing: "Name fehlt",
  invalidToolsTenantMissing: "Mandant fehlt",
  invalidToolsManagerMissing: "Manager fehlt",
  invalidToolsCategoryMissing: "Kategorie fehlt",
  invalidToolsToolIdMissing: "Geräte-ID fehlt",
  invalidToolsDailyRateMissing: "Tagessatz fehlt",
  invalidToolsMinuteRateMissing: "Minutensatz fehlt",
  invalidToolsAllTenants: "Alle Mandanten",
  workPackageFormName: "Name",
  workPackageFormCalculatedHours: "Kalkulierte Stunden",
  workPackageFormPlannedHours: "Geplante Stunden",
  workPackageFormCommuteExpensesEnabledLabel: "Wegegeld wird gezahlt",
  workPackageFormServiceCategoryLabel: "Leistungskategorie",
  workPackageFormLocationLabel: "Ausführungsort",
  workPackageFormNoteLabel: "Hinweis zur Ausführung an die Kolonne",
  workPackageFormServiceCategoryError: "Bitte wähle eine Leistungskategorie.",
  workPackageFormConstructionSiteManagerLabel: "Bauleiter",
  workPackageFormConstructionSiteManagerError: "Bitte wähle einen Bauleiter.",
  workPackageFormWageTypeLabel: "Lohnart",
  timeApprovalConstructionSiteManagerAccount: "Bauleiterkonto",
  timeApprovalNoLocation: "Position nicht verfügbar.",
  timeApprovalOutdatedLocation: "Position ist veraltet.",
  timeApprovalLocationCurrentStartLocationTooFarAway:
    "Aktueller Ausführungsort: Die Entfernung des Einbuchens zum aktuellen Ausführungsort ist ungewöhnlich hoch (Fahrtweg: {distanceToWorkLocation}, Fahrzeit: {timeToWorkLocation}).",
  timeApprovalLocationPreviousLocationTooFarAway:
    "Vorheriger Ausführungsort: Die Entfernung des Einbuchens zum vorherigen Ausführungsort ist ungewöhnlich hoch (Fahrtweg: {distanceToWorkLocation}, Fahrzeit: {timeToWorkLocation}).",
  timeApprovalLocationCurrentStopLocationTooFarAway:
    "Aktueller Ausführungsort: Die Entfernung des Ausbuchens zum aktuellen Ausführungsort ist ungewöhnlich hoch (Fahrtweg: {distanceToWorkLocation}, Fahrzeit: {timeToWorkLocation}).",
  timeApprovalLocationNextLocationTooFarAway:
    "Nächster Ausführungsort: Die Entfernung des Ausbuchens zum nächsten Ausführungsort ist ungewöhnlich hoch (Fahrtweg: {distanceToWorkLocation}, Fahrzeit: {timeToWorkLocation}).",
  timeApprovalWorkPackageLocationMissing:
    "Entfernung kann nicht berechnet werden, da im Arbeitspaket kein Ausführungsort hinterlegt ist.",
  timeApprovalTableEmployee: "Mitarbeiter/in oder Gerät",
  timeApprovalTableArrivalCommute: "Hinweg",
  timeApprovalTableDepartureCommute: "Rückweg",
  timeApprovalTableFrom: "von",
  timeApprovalTableTo: "bis",
  timeApprovalTableTimeRange: "von - bis",
  timeApprovalShowLocation: "Position auf Karte anzeigen.",
  timeApprovalTableBreak: "Pause",
  timeApprovalTableNet: "Netto",
  timeApprovalTableTask: "Arbeitspaket / Buchungskonto",
  timeApprovalApprovedTooltip:
    "Freigegeben am {approvedDate} Uhr durch {approvedBy}",
  timeApprovalPaidOutTooltip:
    "Exportiert am {exportedAt} Uhr durch {exportedBy}",
  timeApprovalDeletedTooltip:
    "Dieser Zeiteintrag wurde am {deletedAt} Uhr von {deletedBy} gelöscht",
  timeApprovalDeleteButtonTooltipResourceName: "Zeiteintrag von {employee}",
  timeApprovalOverlappingTooltip:
    "Eintrag kann nicht freigegeben werden, da Überschneidungen mit anderen Einträgen bestehen.",
  timeApprovalCommuteTimeErrorTooltip:
    "Eintrag kann nicht freigegeben werden, da die Wegezeit nicht automatisch berechnet werden konnte.",
  timeApprovalCreateTimeTrackingDialogDateLabel: "Datum",
  timeApprovalFiltersDateRange: "Zeitraum",
  timeApprovalFiltersGrouping: "Gruppierung",
  timeApprovalFiltersGroupingTeam: "Kolonne",
  timeApprovalFiltersGroupingWorkPackage: "Arbeitspaket / Buchungskonto",
  timeApprovalFiltersGroupingEmployee: "Mitarbeiter / Gerät",
  timeApprovalFiltersGroupNameTeam: "Kolonnenname",
  timeApprovalFiltersGroupNameWorkPackage:
    "Arbeitspaketname / Baustellennummer",
  timeApprovalFiltersGroupNameEmployee: "Name / Nummer",
  timeApprovalFiltersSearch: "Suche",
  timeApprovalFiltersStatus: "Status",
  timeApprovalAnomaliesDropdownLabel: "Anomalien",
  timeApprovalAnomaliesDropdownPlaceholder: "Anomalien auswählen...",
  timeApprovalAnomaliesDropdownOption_exceedsMaxHours:
    "Überschreitung maximale Arbeitszeit",
  timeApprovalAnomaliesDropdownOption_employeeMultipleArrivalCommute:
    "Mehrere Wegezeiten Hinfahrt",
  timeApprovalAnomaliesDropdownOption_employeeMultipleDepartureCommute:
    "Mehrere Wegezeiten Rückfahrt",
  timeApprovalAnomaliesDropdownOption_multipleArrivalDriversOnTimeTracking:
    "Mehrere Fahrer Hinfahrt",
  timeApprovalAnomaliesDropdownOption_multipleDepartureDriversOnTimeTracking:
    "Mehrere Fahrer Rückfahrt",
  timeApprovalAnomaliesDropdownOption_arrivalCommuteTimeBiggerThanTrackingSwitchTime:
    "Wegezeit Hinfahrt größer als Wechselzeit",
  timeApprovalAnomaliesDropdownOption_departureCommuteTimeBiggerThanTrackingSwitchTime:
    "Wegezeit Rückfahrt größer als Wechselzeit",
  timeApprovalAnomaliesDropdownOption_commuteTimeWithoutCalculation:
    "Wegezeit ohne Berechnung",
  timeApprovalColumnsCommute: "Wegezeit",
  timeApprovalColumnsEmployee: "Mitarbeiter",
  timeApprovalColumnsFrom: "Von",
  timeApprovalColumnsTo: "Bis",
  timeApprovalColumnsBreak: "Pause",
  timeApprovalColumnsNet: "Netto",
  timeApprovalColumnsTeam: "Kolonne",
  timeApprovalColumnsTask: "Arbeitspaket / Buchungskonto",
  timeApprovalColumnsStatus: "Status",
  timeApprovalColumnsActions: "Aktionen",
  timeApprovalCellTotal: "Gesamt",
  timeApprovalTaskInformation: "Auftragsinformationen",
  timeApprovalTaskModalAssignWorkPackageTitle: "Arbeitspaket zuordnen",
  timeApprovalTaskModalAssignWorkPackageButton: "Zuordnen",
  timeApprovalActionButtonsTaskModalTooltip: "Arbeitspaket zuordnen",
  timeApprovalActionButtonsApproveTooltip: "Freigeben",
  timeApprovalActionButtonsUnapproveTooltip: "Freigabe rückgängig machen",
  timeApprovalActionButtonsEditTooltip: "Buchungen bearbeiten",
  timeApprovalEditTimeEntriesFormModalTitle: "Buchungen bearbeiten",
  timeApprovalCreateTimeEntriesFormModalTitle: "Buchungen erstellen",
  timeApprovalNoTeam: "(Ohne Kolonne)",
  timeApprovalOverlappingUndoTooltip:
    "Eintrag kann nicht freigegeben werden, da er sich mit anderen Einträgen überschneidet.",
  timeApprovalTimeEntriesFormModalSave: "Speichern",
  timeApprovalTimeEntriesFormModalSaveWithApprove: "Speichern und freigeben",
  timeApprovalTimeEntriesFormModalSecondPrecisionLabel: "sekundengenau",
  timeApprovalTimeEntriesFormModalCommuteTimeError:
    "Wegezeit konnte nicht automatisch berechnet werden. Bitte gib die Wegezeit manuell ein.",
  timeApprovalNewAbsenceFormModalTitle: "Abwesenheitsbuchung erstellen",
  timeApprovalNewAbsenceFormModalHint:
    "Um Abwesenheiten über mehrere Tage hinweg zu planen, nutze den Bereich",
  timeApprovalNewAbsenceFormModalHintLink: "Abwesenheiten",
  timeApprovalNewAbsenceFormEmployeePlaceholder: "Mitarbeiter auswählen",
  timeApprovalNewTrainingFormModalTitle: "Weiterbildung eintragen",
  timeApprovalSpeedDialNewWorkingHours: "Neue Arbeitszeit",
  timeApprovalSpeedDialNewAbsence: "Neue Abwesenheit",
  timeApprovalSpeedDialNewTraining: "Neue Weiterbildung",
  timeApprovalNetDurationWholeDayTooltip:
    "Als Netto-Zeit für die Abwesenheit wird die zugrundeliegende vertragliche Arbeitszeit des jeweiligen Mitarbeitenden angesetzt.",
  timeApprovalMultipleArrivalCommuteTooltip:
    "Für diesen Mitarbeitenden gibt es zwei Zeitbuchungen an einem Tag, bei denen ein Abfahrtsort für die Berechnung von Wegegeld ausgewählt wurde.",
  timeApprovalMultipleDepartureCommuteTooltip:
    "Für diesen Mitarbeitenden gibt es zwei Zeitbuchungen an einem Tag, bei denen ein Zielort für die Berechnung von Wegegeld ausgewählt wurde.",
  timeApprovalMultipleDriversOnTimeTrackingArrivalTooltip:
    "Bei dieser Zeitbuchung wurden bei der Hinfahrt mehrere Mitarbeitende als Fahrer ausgewählt.",
  timeApprovalMultipleDriversOnTimeTrackingDepartureTooltip:
    "Bei dieser Zeitbuchung wurden bei der Rückfahrt mehrere Mitarbeitende als Fahrer ausgewählt.",
  timeApprovalArrivalCommuteTimeBiggerThanTrackingSwitchTimeTooltip:
    "Die Wegezeit ist größer als die Zeit zwischen dem letzten Ausbuchen und dem Einbuchen auf diesem Arbeitspaket.",
  timeApprovalDepartureCommuteTimeBiggerThanTrackingSwitchTimeTooltip:
    "Die Wegezeit ist größer als die Zeit zwischen dem Ausbuchen auf diesem Arbeitspaket und dem nächsten Einbuchen.",
  invalidDateRangeInfo: `
    Bitte wähle einen gültigen Zeitraum aus.
    <br />
    Um übermäßig lange Ladezeiten zu vermeiden, kannst du nur einen Zeitraum
    von <strong>maximal 6 Monaten</strong> wählen.
  `,
  timeApprovalEmptyResultInfo:
    "Für die ausgewählten Suchkriterien konnten keine Buchungen gefunden werden.",
  timeApprovalActionButtonsHistoryTooltip: "Änderungshistorie anzeigen",
  timeApprovalToolTooltip: "Geräte werden automatisch freigegeben.",
  timeApprovalConfirmDialogTitle: "Überschreiten der maximalen Arbeitszeit",
  timeApprovalConfirmDialogText:
    "Für folgende Mitarbeitende würde die Gesamtzahl der gebuchten Stunden 10 übersteigen:",
  timeApprovalConfirmDialogOk: "Trotzdem freigeben",
  timeApprovalConfirmDialogCancel: "Abbrechen",
  timeApprovalFooterTotal: "Gesamt",
  timeApprovalFooterTotalUnit: "Buchungen",
  timeApprovalFooterEmployeesCount: "# gebuchte Personen",
  timeApprovalFooterEmployeesCountLong: "Anzahl der gebuchten Personen",
  timeApprovalFooterToolsCount: "# gebuchte Geräte",
  timeApprovalFooterToolsCountLong: "Anzahl der gebuchten Geräte",
  timeApprovalFooterProductiveHours: "𝛴 Produktivstunden",
  timeApprovalFooterProductiveHoursLong:
    "Summe der produktiven Personenstunden",
  timeApprovalFooterNonProductiveHours: "𝛴 sonst. Stunden",
  timeApprovalFooterNonProductiveHoursLong:
    "Summe der nicht-produktiven Personenstunden",
  timeApprovalFooterToolsHours: "𝛴 Gerätestunden",
  timeApprovalFooterToolsHoursLong: "Summe der Gerätestunden",
  setWorkPackageFormWorkPackageLabel: "Arbeitspaket",
  setWorkPackageFormWorkPackageError: "Bitte wähle ein Arbeitspaket",
  editTimeEntryAddEntry: "Eintrag hinzufügen",
  editTimeEntryErrorOverlapping:
    "Diese Zeit überschneidet sich mit anderen Zeitenbuchungen",
  editTimeEntryConflictTooltipHeader: "Steht im Konflikt mit folgenden Zeiten:",
  editTimeEntryErrorRequired: "Pflichtfeld",
  editTimeEntryErrorStartTimeGreaterThanStopTime: "Start muss vor Ende liegen",
  editTimeEntryErrorStopTimeLowerThanStartTime: "Ende muss hinter Start liegen",
  editTimeEntryNoEmployeeAvailable: "Keine Mitarbeiter/Geräte verfügbar",
  editTimeEntryNoWorkPackagesAvailable: "Keine Arbeitspakete verfügbar",
  editTimeEntrySelectEmployee: "Mitarbeiter/Gerät auswählen",
  editTimeEntrySelectWorkPackage: "Arbeitspaket auswählen",
  editTimeEntryUnknownWorkPackage: "Unbekanntes Arbeitspaket",
  loggedInAs: "Angemeldet als",
  loginAnotherUser: "Neuen Benutzer anmelden",
  loginPageTitle: "Willkommen bei der grewe-gruppe",
  loginPageTextHtml: `
    <p>
      Wir befinden uns zurzeit noch in der <strong>Testphase</strong>.
      Es kann also sein, dass Du beim Benutzen noch Kinderkrankheiten entdeckst.
      Die gebuchten Zeiten werden bereits von der Lohnbuchhaltung verwendet.
    </p>
    <p>
      Falls Du etwas entdeckst, was nicht so funktioniert, wie von Dir
      erwartet, dann schreibe uns bitte eine kurze Mail an <a href="mailto:app@grewe-gruppe.de">app@grewe-gruppe.de</a> und
      teile uns mit, was Du gemacht hast, was passiert ist und was Du erwartet
      hättest.
    </p>
    <p>
      Die Anmeldung erfolgt über Deinen Microsoft-Account. Beim ersten Login
      musst Du der App lediglich die benötigten Zugriffsrechte gewähren und im
      Anschluss kann es auch schon losgehen!
    </p>
  `,
  moreLoadingInfo:
    "Das Laden dauert länger als erwartet. Bitte gedulde dich noch etwas. Wenn es sehr lange dauert überprüfe ob du eine Internet-Verbindung hast.",
  selectForeman: "Kolonnenverantwortlichen wählen",
  foremanRequired: "Bitte einen Kolonnenverantwortlichen wählen.",
  timeEntryExport: "Zeitenexport",
  timeEntryExportDescription:
    "Exportiere Zeitenbuchungen für die Lohnbuchhaltung.",
  timeEntryExportDateRangeLabel: "Zeitraum",
  timeEntryExportTenantLabel: "Mandant",
  timeEntryExportTenantNoOptions: "Keine Mandanten auswählbar",
  timeEntryExportIncludeAlreadyExportedLabel:
    "Auch bereits exportierte Zeiten einschließen",
  timeEntryExportSubmitButtonLabel: "Zeiten herunterladen",
  timeEntryExportFileNameFromPlaceholder: "{von}",
  timeEntryExportFileNameUntilPlaceholder: "{bis}",
  timeEntryExportFileNameTenantPlaceholder: "{mandant}",
  timeEntryExportStepOneTitle: "Schritt 1",
  timeEntryExportStepOneSubtitle: "Mandanten wählen",
  timeEntryExportStepTwoTitle: "Schritt 2",
  timeEntryExportStepTwoSubtitle: "Export konfigurieren",
  timeEntryExportStepThreeTitle: "Schritt 3",
  timeEntryExportStepThreeSubtitle: "Zeiten als CSV-Datei herunterladen",
  timeEntryExportPreviewTitle: "Export-Vorschau",
  timeEntryExportPreviewEmptyResultTitle: "Keine Buchungen gefunden!",
  timeEntryExportPreviewEmptyResultContent:
    "Entweder haben Sie die Buchungen bereits exportiert oder es wurden keine Buchungen zu den gewählten Exportkriterien gefunden.",
  timeEntryExportPreviewChooseCriteria:
    "Wähle den Mandanten und den Zeitraum, um die beinhalteten Buchungen zu sehen",
  closeSnackbar: "Schließen",
  offlineBannerMessage: "Du bist offline.",
  openSidebar: "Navigation öffnen",
  closeSidebar: "Navigation schließen",
  monitoringRunningTimeTrackingsHeadline: "Aktive Zeitbuchungen",
  monitoringRunningTimeTrackingsActive: "aktiv",
  monitoringRunningTimeTrackingsEmployeeCountTextSingular:
    "aktive(r) Mitarbeitende(r)",
  monitoringRunningTimeTrackingsEmployeeCountTextPlural: "aktive Mitarbeitende",
  monitoringRunningTimeTrackingsToolCountTextSingular: "aktives Gerät",
  monitoringRunningTimeTrackingsToolCountTextPlural: "aktive Geräte",
  monitoringNoRunningTimeTrackings: "Aktuell ist niemand eingebucht...",
  monitoringTimeEntriesTableHeadEmployee: "Mitarbeiternde / Geräte",
  monitoringTimeEntriesTableHeadFrom: "seit",
  monitoringTimeEntriesTableHeadWorkPackage: "Arbeitspaket oder Bauleiter",
  monitoringConstructionSiteManagerAccountOf: "Bauleiterkonto von {name}",
  monitoringConstructionSiteManagerAccount: "Bauleiterkonto",
  monitoringTraining: "Weiterbildung",
  teamPlanning: "Kolonnenbezogene Planung",
  teamPlanningDescription: "Prüfe die Auslastung deiner Kolonnen.",
  teamPlanningCalendarWeekAbbr: "KW",
  teamPlanningTeamsTableHeader: "Kolonnen",
  teamPlanningEmployeesTableHeader: "Mitarbeitende/r",
  teamPlanningConstructionSitesTableHeader: "Baustellen",
  teamPlanningPreviousWeek: "vorherige Woche",
  teamPlanningNextWeek: "nächste Woche",
  teamPlanningPreviousMonth: "vorheriger Monat",
  teamPlanningNextMonth: "nächster Monat",
  teamPlanningPreviousThreeMonths: "vorherige 12 Wochen",
  teamPlanningNextThreeMonths: "nächste 12 Wochen",
  teamPlanningTeamlessEmployeesSectionHeader: "Mitarbeiter ohne Kolonne",
  teamPlanningTeamlessToolsSectionHeader: "Geräte ohne Kolonne",
  teamPlanningWeekDropdownCurrentWeekOption: "Aktuelle Woche",
  teamPlanningExpandAll: "Alle aufklappen",
  teamPlanningDateUnitSelectWeek: "Woche",
  teamPlanningDateUnitSelectMonth: "Monat",
  teamPlanningDateUnitSelectThreeMonths: "12 Wochen",
  teamPlanningGroupingSelectTeam: "Kolonne",
  teamPlanningGroupingSelectEmployee: "Mitarbeiter",
  teamPlanningGroupingSelectConstructionSite: "Baustelle",
  teamPlanningPlanEntryTypeSelectAll: "Alle",
  teamPlanningPlanEntryTypeSelectWorkPackage: "Arbeitspakete",
  teamPlanningPlanEntryTypeSelectAbsence: "Abwesenheiten",
  teamPlanningDrawerWorkPackageLabel: "Arbeitspaket",
  teamPlanningDrawerDateRangeLabel: "Zeitraum",
  teamPlanningDrawerManagerLabel: "Bauleiter",
  teamPlanningDrawerServiceCategoryLabel: "Leistungskategorie",
  teamPlanningDrawerPlanningLink: "Zur Planung",
  teamPlanningDrawerTimePeriodModalLink: "Zeitraum verschieben",
  teamPlanningTimePeriodModalTitle: "Kolonnenplanung verschieben",
  teamPlanningTimePeriodModalSubmitButton: "Verschieben",
  teamPlanningTimePeriodModalByLabel: "um",
  teamPlanningTimePeriodModalToLabel: "auf den",
  teamPlanningTimePeriodModalUnitDay: "Tage",
  teamPlanningTimePeriodModalUnitWeek: "Wochen",
  teamPlanningTimePeriodModalCountLabel: "Anzahl",
  teamPlanningTimePeriodModalUnitLabel: "Tage oder Wochen",
  teamPlanningTimePeriodModalDateLabel: "Datum",
  appAdmin: "Debug & Config",
  appAdminSettings: "Config & Debug",
  appAdminStats: "Stats",
  settingsButton: "Einstellungen",
  settingsCloseButton: "Einstellungen schließen",
  settingsHeader: "Einstellungen",
  settingsLanguageTitle: "Sprache",
  settingsBetaTitle: "Modus",
  settingsBetaDescription:
    "Wenn du den BETA-Modus aktivierst, kannst du neue Features testen.",
  settingsBetaDisabled: "NORMAL",
  settingsBetaEnabled: "BETA",
  SettingsWereSaved: "Einstellungen wurden gespeichert",
  statistics: "Statistiken",
  statisticsDescription: "Prüfe die Nutzung der grewe-gruppe App.",
  statisticsCurrentTenant: "Aktueller Mandant",
  statisticsAllTenants: "Gesamte Gruppe",
  statisticsHeaderStatistics: "Statistiken",
  statisticsHeaderTotal: "Insgesamt",
  statisticsColumnTitleTeams: "Kolonnen (durchschn. gew. MA / Kolonne)",
  statisticsColumnTitleTeamsTooltip:
    "Ermittelt mit dem Datum seit dem die Kolonne in der Grewe-Gruppe App erstellt wurde.",
  statisticsColumnTitleEmployees: "Mitarbeiter insgesamt",
  statisticsColumnTitleEmployeesTooltip:
    "Ermittelt mit dem Start- oder Erstelldatum",
  statisticsColumnTitleBlueCollarEmployees:
    "Gewerbliche Mitarbeiter (vs. Mitarbeiter insg.)",
  statisticsColumnTitleBlueCollarEmployeesTooltip:
    "Ermittelt mit dem Start- oder Erstelldatum und der Rolle gewerbliche Mitarbeiter",
  statisticsColumnTitleConstructionSiteManagers:
    "Bauleiter (inkl. GLs) (vs. gew. MA)",
  statisticsColumnTitleConstructionSiteManagersTooltip:
    "Ermittelt mit dem Start- oder Erstelldatum und der Rolle Bauleiter ink. Geschäftsleitung",
  statisticsColumnTitleWorkPackages:
    "Erstellte Arbeitspakete (Durchschn. Arbeitspakete pro Baustelle)",
  statisticsColumnTitleWorkPackagesTooltip:
    "Ermittelt mit dem Datum seit dem das Arbeitspaket in der Grewe-Gruppe App erstellt wurde. (Arbeitspakete geteilt durch alle Baustellen mit min. einem Arbeitspaket)",
  statisticsColumnTitleConstructionSites: "Aktive Baustellen",
  statisticsColumnTitleConstructionSitesTooltip:
    "Ermittelt mit dem Datum Seit dem die Grewe-Gruppe App erstmals die Baustelle erfasst/synchronisiert hat.",
  statisticsColumnTitleConstructionSitesWithWorkPackages:
    "Baustellen mit Arbeitspaketen (vs. Baustellen insg.)",
  statisticsColumnTitleAppendedEmployeeTimeEntries:
    "Manuell erfasste Buchungen (vs. Buchungen insg.)",
  statisticsColumnTitleAppendedEmployeeTimeEntriesTooltip:
    "Alle Zeitbuchungen mit der Eingenschaft ahngehängt (append)",
  statisticsColumnTitleModifiedEmployeeTimeEntries:
    "Editierte Buchungen (vs. Buchungen insg.)",
  statisticsColumnTitleModifiedEmployeeTimeEntriesTooltip:
    "Alle Zeitbuchungen mit der Eingenschaft geändert (modified)",
  statisticsColumnTitleApprovedUnexportedEmployeeTimeEntries:
    "Freigegeben aber noch nicht exportiert",
  statisticsColumnTitleApprovedUnexportedEmployeeTimeEntriesTooltip:
    "Alle Zeitbuchungen mit der Eingenschaft freigegben aber nicht exportiert",
  statisticsColumnTitleTimeEntriesTrackedByDistinctEmployees:
    "Anzahl Buchungen von individ. Mitarbeitern",
  statisticsColumnTitleTimeEntriesTrackedByDistinctEmployeesTooltip:
    "Anzahl der gestarteten Zeitbuchungen pro Mitarbeiter zusammengefasst",
  statisticsColumnTitleTimeEntriesApprovedByDistinctEmployees:
    "Anzahl freigegebene Buchungen von individ. Mitarbeitern",
  statisticsColumnTitleTimeEntriesApprovedByDistinctEmployeesTooltip:
    "Anzahl der freigebenen Zeitbuchungen pro Mitarbeiter zusammengefasst",
  statisticsColumnTitleAverageDaysBetweenStartAndApproval:
    "Durchschnittliche Anzahl Tage zwischen Buchung und Freigabe",
  statisticsColumnTitleAverageDaysBetweenStartAndApprovalTooltip:
    "Die Summe aller Tage dividiert durch die Anzahl aller Tage von Startdatum bis Abnahmedatum.",
  statisticsColumnTitleAverageDaysBetweenApprovalAndExport:
    "Durchschnittliche Anzahl Tage zwischen Freigabe und Export",
  statisticsColumnTitleAverageDaysBetweenApprovalAndExportTooltip:
    "Die Summe aller Tage dividiert durch die Anzahl aller Tage von Abnahmedatum bis Exportdatum.",
  statisticsColumnTitleAverageDaysBetweenStartAndExport:
    "Durchschnittliche Anzahl Tage zwischen Buchung und Export",
  statisticsColumnTitleAverageDaysBetweenStartAndExportTooltip:
    "Die Summe aller Tage dividiert durch die Anzahl aller Tage von Startdatum bis Exportdatum.",
  statisticsColumnTitleExportedEmployeeTimeEntries: "Exportierte Buchungen",
  statisticsColumnTitleExportedEmployeeTimeEntriesTooltip:
    "Alle Zeitbuchungen die exportiert wurden",
  statisticsColumnTitleEmployeeTimeEntries: "Buchungen insgesamt",
  statisticsColumnTitleEmployeeTimeEntriesTooltip:
    "Alle gestarteten Zeitbuchungen",
  statisticsRowTotalTeams: "Insgesamt",
  statisticsRowAddedTeams: "Neu",
  statisticsRowDeletedTeams: "Gelöscht",
  statisticsRowAddedEmployees: "Neu",
  statisticsRowResignedEmployees: "Gekündigt",
  statisticsRowTotalEmployees: "Insgesamt",
  statisticsRowAddedConstructionSites: "Neu",
  statisticsRowDeletedConstructionSites: "Gelöscht",
  statisticsRowTotalConstructionSites: "Insgesamt",
  statisticsColumnTitleAverageCommuteTime:
    "Durchschnittliche Wegezeiten in Minuten",
  statisticsColumnTitleAverageCommuteTimeTooltip:
    "Die Summe der Wegezeiten aller Fahrten als Fahrer/Beifahrer dividiert durch die Anzahl der Fahrten als Fahrer/Beifahrer",
  statisticsColumnTitleCommuteTimeCounts:
    "Anzahl Fahrten Baustelle <> Betriebshof/Zuhause",
  statisticsColumnTitleCommuteTimeCountsTooltip:
    "Anzahl der Hin-/ Rückwegauswahl pro Mitarbeiter zusammengefasst",
  statisticsRowAverageCommuteTimeAsDriver: "Fahrer/in",
  statisticsRowAverageCommuteTimeAsPassenger: "Beifahrer/in",
  statisticsRowCommuteTimeCountsArrivalSite: "Hinfahrten vom Betriebshof",
  statisticsRowCommuteTimeCountsArrivalHome: "Hinfahrten von Zuhause",
  statisticsRowCommuteTimeCountsDepartureSite: "Rückfahrten zum Betriebshof",
  statisticsRowCommuteTimeCountsDepartureHome: "Rückfahrten nach Zuhause",
  timeEntryHistoryModalTitle: "Änderungshistorie",
  timeEntryHistoryModalTitleEntryFrom: "Buchung vom",
  timeEntryHistoryModalChangeInfoStartAction: "Zeiterfassung gestartet von",
  timeEntryHistoryModalChangeInfoAppendAction:
    "Zeiterfassung manuell erstellt von",
  timeEntryHistoryModalChangeInfoStopAction: "Zeiterfassung gestoppt von",
  timeEntryHistoryModalChangeInfoAutoStopAction:
    "Zeiterfassung automatisch gestoppt durch das Gerät von",
  timeEntryHistoryModalChangeInfoForceStopAction:
    "Zeiterfassung im Monitoring gestoppt von",
  timeEntryHistoryModalChangeInfoModifyAction: "Manuelle Änderungen von",
  timeEntryHistoryModalChangeInfoApproveAction: "Freigegeben von",
  timeEntryHistoryModalChangeInfoUnapproveAction: "Freigabe zurückgenommen von",
  timeEntryHistoryModalChangeInfoExportAction: "Exportiert von",
  timeEntryHistoryModalChangeInfoUpdateCommuteTypeAction: "Wegezeit erfasst",
  timeEntryHistoryModalChangeInfoCommuteTimeAction:
    "Wegezeit wurde im Hintergrund berechnet",
  timeEntryHistoryWorkPackageLabel: "Arbeitspaket",
  timeEntryHistoryManagerLabel: "Bauleiter",
  timeEntryHistoryAbsenceLabel: "Buchungskonto",
  myTimeEntries: "Meine Zeiten",
  myTimeEntriesDescription: "Überprüfe den Status deiner gebuchten Zeiten.",
  myTimeEntriesFromLabel: "von",
  myTimeEntriesToLabel: "bis",
  myTimeEntriesArrivalCommuteLabel: "Hinweg",
  myTimeEntriesDepartureCommuteLabel: "Rückweg",
  myTimeEntriesTotalCommuteLabel: "Summe der Wegezeit",
  myTimeEntriesBreakLabel: "Pause",
  myTimeEntriesNetLabel: "Netto",
  myTimeEntriesWorkPackageLabel: "Arbeitspaket",
  myTimeEntriesManagerLabel: "Bauleiter",
  myTimeEntriesAbsenceLabel: "Buchungskonto",
  myTimeEntriesStatusLabel: "Status",
  myTimeEntriesEmptyResultTitle: "Keine Zeiteinträge für diese Woche",
  myTimeEntriesEmptyResultContent: "Bitte überprüfen Sie ihre Filterkriterien",
  myTimeEntriesEmployeeSelectAllOption: "Alle Mitarbeiter",
  timeTrackingSynchronizedTitle: "Buchung synchronisiert",
  timeTrackingOfflineTitle: "Buchung nicht synchronisiert",
  timeTrackingOfflineDescription:
    "Deine Zeiten sind zwischengespeichert und werden automatisch synchronisiert sobald du mit dem Internet verbunden bist.",
  timeTrackingPositionErrorTitle: "Standort-Ermittlung blockiert",
  timeTrackingPositionErrorContentParagraph1:
    "Für das Starten und Stoppen der Zeiterfassung wird dein Standort benötigt. Bitte erlaubt daher die GPS-Ermittlung, wenn du danach gefragt wirst.",
  timeTrackingPositionErrorContentParagraph2:
    "Sollte der GPS-Empfang deaktiviert sein, kannst du es in den Systemeinstellungen deines Smartphones aktivieren.",
  timeTrackingPositionUnavailableErrorContentParagraph1:
    "Positionsbestimmung ist leider fehlgeschlagen.",
  timeTrackingPositionTimeoutErrorContentParagraph1:
    "Positionsbestimmung hat zu lange gedauert.",
  timeTrackingPositionRetryableErrorContentParagraph2:
    "Falls Du Dich in einem Gebäude, Auto oder z.B. unter einer Brücke befindest, gehe bitte ins Freie und versuche es dort noch einmal.",
  timeTrackingPositionUnsupportedErrorContentParagraph1:
    "Dein Browser unterstützt leider kein GPS-Tracking.",
  timeTrackingPositionUnsupportedErrorContentParagraph2:
    "Bitte aktualisiere deinen Browser oder verwende einen anderen modernen Browser (z.B. chrome).",
  timeTrackingPositionUnknownErrorContentParagraph1:
    "Ein unbekannter Fehler ist aufgetreten.",
  timeTrackingPositionErrorConfirm: "vertanden",
  taskBarAgeTooltip:
    "Es gibt Zeitbuchungen die älter als {ageInDays} Tage sind.",
  searchFieldLabel: "Suche",
  toolAdministrationCategoryFieldLabel: "Kategorie",
  toolAdministrationBillabilityFieldLabel: "Geräteart",
  toolAdministrationBillabilityOptionBillable: "Leistungsgeräte",
  toolAdministrationBillabilityOptionNonBillable: "Gemeinkostengeräte",
  toolAdministrationStatusFieldLabel: "Status",
  toolAdministrationStatusOptionActive: "Aktiv",
  toolAdministrationStatusOptionInactive: "Inaktiv",
  toolAdministrationEmptyResultsInfo:
    "Für die ausgewählten Suchkriterien konnten keine Geräte gefunden werden.",
  notificationsTitle: "Benachrichtigungen",
  notificationsToday: "Heute",
  notificationsMarkAsRead: "Alle als gelesen markieren",
  notificationsActionResponsibilityTaken: "Übernahme",
  notificationsActionResponsibilityReturned: "Rückgabe",
  notificationsActionToolDataChanged: "Datenänderung",
  notificationsActionToolPermanentDecommissioned: "Stillgelegt",
  notificationsActionToolPermanentRecommissioned: "Reaktiviert",
  notificationsSender: "Geändert durch",
  notificationsTool: "Gerät",
  notificationsOccurrence: "Vorgang",
  notificationsTime: "Uhrzeit",
  notificationsDescription: "Beschreibung",
  notificationsDescriptionFromTo: 'geändert von "{from}" zu "{to}"',
  notificationsDescriptionTo: 'geändert zu "{to}"',
  notificationsDescriptionResponsibleEmployee: "Verantwortliche/r",
  notificationsDescriptionToolReturned: 'Zurückgegeben von "{by}"',
  notificationsDescriptionManager: "Manager",
  notificationsDescriptionToolPermanentDecommissioned:
    'Mit dem {decommissionedAt} ist das Gerät von "{employee}" mit dem Grund "{decommissionReason}" stillgelegt worden',
  notificationsDescriptionToolPermanentRecommissioned:
    "Das Gerät wurde reaktiviert.",
  notificationsFilterUnread: "Ungelesen",
  notificationsEmptyResult:
    "Für die ausgewählten Suchkriterien konnten keine Benachrichtigungen gefunden werden.",
  organigram: "Organigramm",
  organigramExportAsPdf: "Organigramm als PDF exportieren",
  organigramOrganizationTitle: "Standort",
  organigramExpandAll: "Alles ausklappen",
  organigramCollapseAll: "Alles einklappen",
  organigramCompact: "Kompakt",
  organigramLayoutLeft: "Layout: linksbündig",
  organigramLayoutRight: "Layout: rechtsbündig",
  organigramLayoutTop: "Layout: oben",
  organigramLayoutBottom: "Layout: unten",
  wageTypeChargableHours: "Produktivstunden",
  wageTypeVacation: "Urlaub",
  wageTypeVacationAbbreviation: "U",
  wageTypeIllness: "Krankheit",
  wageTypeIllnessAbbreviation: "K",
  wageTypeBankHolidays: "Feiertag",
  wageTypeBankHolidaysAbbreviation: "F",
  wageTypeCommuting: "Wegezeit",
  wageTypeCommutingAbbreviation: "W",
  wageTypeTraining: "Weiterbildung / Schule",
  wageTypeTrainingAbbreviation: "W/S",
  wageTypePreparation: "Lade- und Rüstzeit",
  wageTypePreparationAbbreviation: "L/R",
  wageTypeAdministration: "Verwaltung",
  wageTypeUnknown: "Unbekannt",
  wageTypeAdministrationAbbreviation: "V",
  wageTypeNumber: "Lohnartnummer",
  resetAppCache: "Alle App-Daten neu laden",
  resetAppCacheSuccessToast: "Alle App-Daten wurden neu geladen.",
  taskBarTitle: "Meine Aufgaben",
  controllingKeyFigures: "Controlling Planung",
  controllingKeyFiguresDescription: "Überblicke die Controlling Planung.",
  controllingKeyFiguresBreadcrumbs: "Controlling Planung",
  controllingKeyFiguresBusinessLine: "Geschäftsfeld",
  controllingKeyFiguresProfitContributionPerHour: "Deckungsbeitrag pro Stunde",
  controllingKeyFiguresPlannedProfitContributionByBusinessLine:
    "Deckungsbeitrag pro Stunde",
  controllingKeyFiguresMonthlyHoursByBusinessLine: "Monatliche Stunden",
  controllingKeyFiguresMonthlyProfitContributionByBusinessLine:
    "Monatlicher Deckungsbeitrag",
  controllingKeyFiguresTotal: "Gesamt",
  controllingKeyFiguresNothingToSeeTitle: "Keine Kennzahlen",
  controllingKeyFiguresNothingToSeeContent:
    "Aktuell sind keine Kennzahlen verfügbar.",
  euroPerHour: "€ pro Stunde",
  businessLine_administration: "Verwaltung",
  businessLine_construction: "Bau",
  businessLine_maintenance: "Instandhaltung",
  businessLine_tree_care: "Baum",
  businessLine_open_space_management: "Freiflächenmanagement",
  businessLine_unknown: "Unbekannt",
  monthlyTargetsUploadButton: "Datei hochladen",
  monthlyTargetsUploadSuccess: "Daten gespeichert.",
  monthlyTargetsUploadFailed: "Fehler beim Speichern der Daten.",
  monthlyEmployeeTargetsButton: "Mitarbeiter-Planstunden",
  monthlyEmployeeTargetsUploadButton: "Hochladen",
  monthlyEmployeeTargetsUploadSuccess: "Daten gespeichert.",
  monthlyEmployeeTargetsUploadFailed: "Fehler beim Speichern der Daten.",
  monthlyEmployeeTargetsErrorMultipleYearsInFile:
    "Die Datei enthält mehrere Jahre",
  monthlyEmployeeTargetsErrorRow: "Zeile %{rowNumber} hat folgende Fehler",
  monthlyEmployeeTargetsErrorInvalidRow:
    "Zeile bereits vorhanden: Kürzel: %{tenant}, Ma-Nr: %{staffNumber}, Geschäftsfeld: %{businessLineCode}, Jahr: %{year}",
  monthlyEmployeeTargetsErrorBusinessLineUnknown:
    "Geschäftsfeld unbekannt: %{businessLineCode}",
  monthlyEmployeeTargetsMoreErrors: "Noch weitere Zeilen enthalten Fehler...",
  monthlyEmployeeTargetsError_tenant_required: "Kürzel fehlt",
  monthlyEmployeeTargetsError_staff_number_required: "MA.-NR fehlt",
  monthlyEmployeeTargetsError_business_line_code_required:
    "Geschäftsfeld fehlt",
  monthlyEmployeeTargetsError_wage_type_required: "Zuordnung fehlt",
  monthlyEmployeeTargetsError_year_required: "Jahr fehlt",
  monthlyEmployeeTargetsError_tenant_unknown: "Mandant unbekannt",
  unknownBackendError: "Ungültig",
  manageWageTypes: "Lohnarten",
  manageWageTypesDescription: "Prüfe, wie Lohnarten interpretiert werden.",
  manageWageTypesTableHeaderName: "Name",
  manageWageTypesTableHeaderNumber: "Nummer",
  manageWageTypesTableHeaderImportInterpretation: "Import interpretation",
  manageWageTypesTableHeaderExportInterpretation: "Export interpretation",
  manageWageTypesTableHeaderCount: "# Buchungen",
  navigationGroup_time: "Zeitmanagement",
  navigationGroup_resources: "Ressourcen",
  navigationGroup_planning: "Planung",
  navigationGroup_controlling: "Controlling",
  navigationGroup_health: "Health-Board",
  dashboardGreeting: "Moin",
  exportButtonLabel: "Exportieren",
  exportButtonErrorMessage:
    "Beim Exportieren ist etwas schiefgelaufen. Bitte versuche es später erneut.",
  resourceTypeSelectLabel: "Mitarbeitende/Geräte",
  resourceTypeSelectOptionAll: "Alle",
  resourceTypeSelectOptionEmployee: "Nur Mitarbeitende",
  resourceTypeSelectOptionTool: "Nur Geräte",
  teamDeletedTooltip: "Kolonne wurde gelöscht",
  azureTeamSyncButton: "Mit MS Teams synchronisieren",
  azureTeamSyncButtonRetry: "MS Teams-Sync neu versuchen",
  azureTeamSyncStatusSyncing: "MS Teams-Sync in Bearbeitung",
  azureTeamSyncStatusSynced: "Mit MS Teams synchronisiert",
  azureTeamSyncModalTitle: "MS Teams-Synchronisation",
  azureTeamSyncModalInfoText:
    "Das synchronisieren mit Microsoft Teams kann bis zu <strong>2 Tage</strong> in Anspruch nehmen.",
  azureTeamSyncModalCreateLabel: "Neues Team erstellen und verbinden",
  azureTeamSyncModalConnectLabel: "Mit einem bestehendem Team verbinden",
  azureTeamSyncModalConnectInputLabel: "Teams-Link einfügen",
  azureTeamSyncModalConnectInputErrorInvalidLink:
    "Bitte füge einen gültigen Teams-Link ein",
  azureTeamSyncModalConnectInputErrorTeamNotFound:
    "Für diesen Link wurde kein Team gefunden.",
  azureTeamSyncModalConnectInputErrorTeamInUse:
    "Dieses Team ist bereits mit einer anderen Baustelle verbunden.",
  azureTeamSyncModalNamePreview:
    'Die neue Gruppe wird in Teams unter "{teamName}" zu finden sein.',
  azureTeamSyncModalAddEmployeesLabel: "Außerplanmäßige Mitarbeiter hinzufügen",
  azureTeamSyncModalSubmitButton: "Synchronisieren",
  azureTeamSyncErrorText:
    "Die Synchronisation mit Teams ist fehlgeschlagen. Versuche es ruhig erneut, deine System-Administratoren wurden bereits über den Fehler informiert.",
  emptySearchResult:
    "Für die ausgewählten Suchkriterien konnten keine Einträge gefunden werden.",
  workingTimeMissing:
    "Die/Der Mitarbeitende(r) war zum angegebenen Datum noch nicht angestellt.",
  quickCheckInCheckOutDialogHeaderArrival: "Hinweg bearbeiten",
  quickCheckInCheckOutDialogHeaderDeparture: "Rückweg bearbeiten",
  quickCheckInCheckOutDialogResourceTitleArrival: "Hinweg von {resourceName}",
  quickCheckInCheckOutDialogResourceTitleDeparture:
    "Rückweg von {resourceName}",
  quickCheckInCheckOutDialogResourceSubtitleArrival:
    "Woher kommt {resourceName}?",
  quickCheckInCheckOutDialogResourceSubtitleDeparture:
    "Wohin fährt {resourceName} nun?",
  quickCheckInCheckOutDialogDriverSiteArrivalButtonLabel:
    "<b>Vom Betriebshof</b> als <b>Fahrer/in</b>",
  quickCheckInCheckOutDialogDriverSiteDepartureButtonLabel:
    "<b>Zum Betriebshof</b> als <b>Fahrer/in</b>",
  quickCheckInCheckOutDialogPassengerSiteArrivalButtonLabel:
    "<b>Vom Betriebshof</b> als <b>Beifahrer/in</b>",
  quickCheckInCheckOutDialogPassengerSiteDepartureButtonLabel:
    "<b>Zum Betriebshof</b> als <b>Beifahrer/in</b>",
  quickCheckInCheckOutDialogDriverHomeArrivalButtonLabel:
    "<b>Von Zuhause</b> als <b>Fahrer/in</b>",
  quickCheckInCheckOutDialogDriverHomeDepartureButtonLabel:
    "<b>Nach Hause</b> als <b>Fahrer/in</b>",
  quickCheckInCheckOutDialogPassengerHomeArrivalButtonLabel:
    " <b>Von Zuhause</b> als <b>Beifahrer/in</b>",
  quickCheckInCheckOutDialogPassengerHomeDepartureButtonLabel:
    "<b>Nach Hause</b> als <b>Beifahrer/in</b>",
  quickCheckInCheckOutDialogNoCommuteButtonLabel: "Kein Wegegeld",
  todayButtonTooltip: "Heute",
  listEndReachedFooterTitle: "Du hast das Ende erreicht.",
  listEndReachedFooterText:
    "Suchst du noch mehr? Überprüfe, ob deine gesuchten Einträge den Filter-Kriterien oben entsprechen.",
} as const;
