import { useMemo } from "react";
import { matchPath } from "react-router";

import { useCurrentEmployee } from "~api/employees.ts";
import { useFeatures } from "~api/features";
import { usePermitted } from "~auth/usePermitted.ts";

import { hiddenRoutes, navigationDefinitions } from "./navigationDefinitions";

import type {
  NavigationDefinitions,
  NavigationGroup,
  NavigationItem,
  RouteWithProps,
} from "./navigationDefinitions";

export function useNavigationDefinitions(
  definitions: NavigationGroup[] = navigationDefinitions,
  hidden: RouteWithProps[] = hiddenRoutes,
) {
  const permitted = usePermitted();
  const { data: currentEmployee, isLoading: isLoadingEmployee } =
    useCurrentEmployee();
  const groups: NavigationDefinitions = useMemo(
    () =>
      definitions
        .map((group) => ({
          ...group,
          items: group.items.filter((item) =>
            shouldShowItem(
              item,
              currentEmployee?.beta_user,
              permitted(item.route.permissions),
            ),
          ),
        }))
        .filter((group) => group.items.length > 0),
    [currentEmployee?.beta_user, permitted, definitions],
  );

  const hiddenRts = hidden.filter((item) => permitted(item.route.permissions));

  return {
    navigationDefinitions: groups,
    hiddenRoutes: hiddenRts,
    isLoading: isLoadingEmployee,
  };
}

export function isActivePath(pathname: string, path: string): boolean {
  return Boolean(matchPath({ path, end: false }, pathname));
}

function shouldShowItem(
  item: NavigationItem,
  betaUser: boolean | undefined,
  permitted: boolean,
) {
  if (!permitted) {
    return false;
  }

  if (!betaUser) {
    return !item.betaFeature;
  }

  return true;
}

export function useEnabledNavigationDefinitions(
  definitions: NavigationGroup[] = navigationDefinitions,
  hidden: RouteWithProps[] = hiddenRoutes,
) {
  const {
    navigationDefinitions: allNavigationDefinitions,
    isLoading: isLoadingDefinitions,
  } = useNavigationDefinitions(definitions, hidden);
  const { data: features, isLoading: isLoadingFeatures } = useFeatures();

  const groups: NavigationDefinitions = useMemo(
    () =>
      allNavigationDefinitions
        .map((group) => ({
          ...group,
          items: group.items.filter((item) => {
            if (item.featureToggle) {
              const feature = (features || []).find(
                (f) => f.name === item.featureToggle,
              );

              if (!feature || !feature.enabled) {
                return false;
              }
            }

            return true;
          }),
        }))
        .filter((group) => group.items.length > 0),
    [features, allNavigationDefinitions],
  );

  return {
    navigationDefinitions: groups,
    isLoading: isLoadingDefinitions || isLoadingFeatures,
  };
}
