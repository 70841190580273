import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { formatISO } from "date-fns/formatISO";

import { optimisticallyUpdateSyncStatusToRunning } from "~api/sync.ts";
import { tenantKeys } from "~api/tenants";
import { useToast } from "~components/shared/useToast";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext";
import { useTranslation } from "~contexts/I18nContext/I18nContext";
import {
  ControllingJournalEntriesService,
  ControllingProjectsClientNamesService,
  ControllingProjectsManagerStaffNumbersService,
  ControllingProjectsService,
  ControllingWageEntriesService,
} from "~generated";

import type { ServiceCategoryFilterOption } from "../ProjectsJournal/ServiceCategoryFilterOptions";
import type { BusinessLineFilterOptions } from "~components/Controlling/ProjectsJournal/BusinessLineFilterOptions.ts";
import type { AccountGroup, ControllingProjectStatus } from "~generated";

export type SortBy = Parameters<
  typeof ControllingProjectsService.getProjectOverviews
>[12];
export type Direction = Parameters<
  typeof ControllingProjectsService.getProjectOverviews
>[13];

export interface ProjectsFilters {
  numbers?: string[];
  businessLines?: BusinessLineFilterOptions;
  serviceCategories?: ServiceCategoryFilterOption[];
  serviceCategoriesOrderManagement?: string[];
  managerStaffNumbers?: string[];
  searchText?: string;
  statusFilterList?: ControllingProjectStatus[];
  clientNames?: string[];
  startDate?: string;
  endDate?: string;
}
interface ProjectsSortParams {
  sortBy?: SortBy;
  direction?: Direction;
}

type DetailsFilters = { startDate?: string; endDate?: string };
type JournalEntriesFilters = DetailsFilters & { accountGroup: AccountGroup };
type WageEntriesFilters = DetailsFilters & { overheads: boolean };

export const controllingProjectKeys = {
  base: (tenantId: string) =>
    [...tenantKeys.detail(tenantId), "controllingProjects"] as const,
  search: (tenantId: string, searchText: string) =>
    [...controllingProjectKeys.base(tenantId), "search", searchText] as const,
  overviews: (
    tenantId: string,
    filters: ProjectsFilters,
    sortParams: ProjectsSortParams,
  ) =>
    [
      ...controllingProjectKeys.base(tenantId),
      "overviews",
      filters,
      sortParams,
    ] as const,
  totals: (tenantId: string, filters: ProjectsFilters) =>
    [...controllingProjectKeys.base(tenantId), "totals", filters] as const,
  managerStaffNumbers: (tenantId: string) =>
    [...controllingProjectKeys.base(tenantId), "managerStaffNumbers"] as const,
  clientNames: (tenantId: string) =>
    [...controllingProjectKeys.base(tenantId), "clientNames"] as const,
  detail: (tenantId: string, id: string, filters?: DetailsFilters) =>
    [...controllingProjectKeys.base(tenantId), "detail", id, filters] as const,
  wageEntries: (
    tenantId: string,
    projectId: string,
    filters: WageEntriesFilters,
  ) =>
    [
      ...controllingProjectKeys.detail(tenantId, projectId),
      "wageEntries",
      filters,
    ] as const,
  journalEntries: (
    tenantId: string,
    projectId: string,
    filters: JournalEntriesFilters,
  ) =>
    [
      ...controllingProjectKeys.detail(tenantId, projectId),
      "journalEntries",
      filters,
    ] as const,
  syncStatus: (tenantId: string) =>
    [...controllingProjectKeys.base(tenantId), "syncStatus"] as const,
  projectsExportStatus: (tenantId: string, exportId: string | undefined) =>
    [
      ...controllingProjectKeys.base(tenantId),
      "projectsExportStatus",
      exportId,
    ] as const,
};

export function useControllingProjectsSearch(searchText: string) {
  const currentTenant = useCurrentTenant();
  const relevantSearchText = searchText.length >= 2 ? searchText : "";

  return useQuery({
    queryKey: controllingProjectKeys.search(
      currentTenant.id,
      relevantSearchText,
    ),
    queryFn: async () =>
      ControllingProjectsService.searchProjects(
        currentTenant.id,
        relevantSearchText,
      ),
  });
}

export function useControllingProjectOverviews(
  filters: ProjectsFilters,
  sortParams: ProjectsSortParams,
) {
  const currentTenant = useCurrentTenant();
  return useInfiniteQuery({
    queryKey: controllingProjectKeys.overviews(
      currentTenant.id,
      filters,
      sortParams,
    ),
    queryFn: async ({ pageParam }) =>
      ControllingProjectsService.getProjectOverviews(
        currentTenant.id,
        filters.numbers,
        filters.businessLines,
        filters.serviceCategories?.map((c) => c.id),
        filters.serviceCategoriesOrderManagement,
        filters.managerStaffNumbers,
        filters.searchText,
        filters.statusFilterList,
        filters.clientNames,
        filters.startDate,
        filters.endDate,
        pageParam,
        sortParams.sortBy,
        sortParams.direction,
      ),
    getNextPageParam: (lastPage) => lastPage.next_page,
    initialPageParam: 1,
  });
}

export function useControllingProjectTotals(filters: ProjectsFilters) {
  const currentTenant = useCurrentTenant();
  return useQuery({
    queryKey: controllingProjectKeys.totals(currentTenant.id, filters),
    queryFn: async () =>
      ControllingProjectsService.getProjectTotals(
        currentTenant.id,
        filters.numbers,
        filters.businessLines,
        filters.serviceCategories?.map((c) => c.id),
        filters.serviceCategoriesOrderManagement,
        filters.managerStaffNumbers,
        filters.searchText,
        filters.statusFilterList,
        filters.clientNames,
        filters.startDate,
        filters.endDate,
      ),
  });
}

export function useControllingProjectDetails(
  id: string,
  startDate: Date | null,
  endDate: Date | null,
) {
  const currentTenant = useCurrentTenant();
  const startDateString = startDate
    ? formatISO(startDate, { representation: "date" })
    : undefined;

  const endDateString = endDate
    ? formatISO(endDate, { representation: "date" })
    : undefined;

  return useQuery({
    queryKey: controllingProjectKeys.detail(currentTenant.id, id, {
      startDate: startDateString,
      endDate: endDateString,
    }),
    queryFn: async () =>
      ControllingProjectsService.getProjectDetails(
        currentTenant.id,
        id,
        startDateString,
        endDateString,
      ),
  });
}

export function useControllingProjectsManagerStaffNumbers() {
  const currentTenant = useCurrentTenant();
  return useQuery({
    queryKey: controllingProjectKeys.managerStaffNumbers(currentTenant.id),
    queryFn: async () =>
      ControllingProjectsManagerStaffNumbersService.getProjectsManagerStaffNumbers(
        currentTenant.id,
      ),
  });
}

export function useControllingProjectsClientNames() {
  const currentTenant = useCurrentTenant();
  return useQuery({
    queryKey: controllingProjectKeys.clientNames(currentTenant.id),
    queryFn: async () =>
      ControllingProjectsClientNamesService.getProjectsClientNames(
        currentTenant.id,
      ),
  });
}

export function useControllingWageEntries(
  projectId: string,
  overheads: boolean,
  startDate: Date | null,
  endDate: Date | null,
) {
  const currentTenant = useCurrentTenant();
  const startDateString = startDate
    ? formatISO(startDate, { representation: "date" })
    : undefined;

  const endDateString = endDate
    ? formatISO(endDate, { representation: "date" })
    : undefined;
  return useQuery({
    queryKey: controllingProjectKeys.wageEntries(currentTenant.id, projectId, {
      startDate: startDateString,
      endDate: endDateString,
      overheads,
    }),
    queryFn: async () =>
      ControllingWageEntriesService.getWageEntries(
        currentTenant.id,
        projectId,
        overheads,
        startDateString,
        endDateString,
      ),
  });
}

export function useControllingJournalEntries(
  projectId: string,
  accountGroup: AccountGroup,
  startDate: Date | null,
  endDate: Date | null,
) {
  const currentTenant = useCurrentTenant();
  const startDateString = startDate
    ? formatISO(startDate, { representation: "date" })
    : undefined;

  const endDateString = endDate
    ? formatISO(endDate, { representation: "date" })
    : undefined;
  return useQuery({
    queryKey: controllingProjectKeys.journalEntries(
      currentTenant.id,
      projectId,
      { accountGroup, startDate: startDateString, endDate: endDateString },
    ),
    queryFn: async () =>
      ControllingJournalEntriesService.getJournalEntries(
        currentTenant.id,
        projectId,
        accountGroup,
        startDateString,
        endDateString,
      ),
  });
}

export function useControllingDataSyncStatus({
  enabled,
}: {
  enabled: boolean;
}) {
  const currentTenant = useCurrentTenant();

  return useQuery({
    queryKey: controllingProjectKeys.syncStatus(currentTenant.id),
    queryFn: async () =>
      ControllingProjectsService.getControllingDataSyncStatus(currentTenant.id),
    refetchInterval: 5000,
    enabled,
  });
}

export function useSyncControllingData() {
  const currentTenant = useCurrentTenant();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      ControllingProjectsService.syncControllingData(currentTenant.id),
    onMutate: () => {
      optimisticallyUpdateSyncStatusToRunning({
        queryKey: controllingProjectKeys.syncStatus(currentTenant.id),
        queryClient,
      });
    },
  });
}

export function useStartProjectsExport(
  tenantId: string,
  projectNumbers: string[],
  businessLines: BusinessLineFilterOptions,
  serviceCategories: ServiceCategoryFilterOption[],
  serviceCategoriesOrderManagement: string[],
  managerStaffNumbers: string[],
  statuses: ControllingProjectStatus[],
  clientNames: string[],
  startDate: string | undefined,
  endDate: string | undefined,
) {
  const { showErrorToast } = useToast();
  const t = useTranslation();

  return useMutation({
    mutationFn: async () =>
      ControllingProjectsService.startProjectsExport(
        tenantId,
        projectNumbers,
        businessLines,
        serviceCategories?.map((c) => c.id),
        serviceCategoriesOrderManagement,
        managerStaffNumbers,
        statuses,
        clientNames,
        startDate,
        endDate,
      ),
    onSuccess: async (data) => data,
    onError: async () => showErrorToast(t.exportButtonErrorMessage),
  });
}

export function useProjectsExportStatus(
  tenandId: string,
  exportId: string | undefined,
) {
  return useQuery({
    queryKey: controllingProjectKeys.projectsExportStatus(tenandId, exportId),
    queryFn: async () =>
      ControllingProjectsService.getProjectsExportStatus(tenandId, exportId),
    refetchInterval: 5000,
    enabled: !!exportId,
  });
}
