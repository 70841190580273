/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommuteType } from '../models/CommuteType';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CommuteTimesService {
    /**
     * returns the calculated commute time
     * @param commuteType commute_type
     * @param trackingTime tracking_time
     * @param trackingLatitude tracking_latitude
     * @param trackingLongitude tracking_longitude
     * @param employeeId employee_id
     * @param direction
     * @returns any returns commute times
     * @throws ApiError
     */
    public static getCommuteTime(
        commuteType: CommuteType,
        trackingTime: string,
        trackingLatitude: number,
        trackingLongitude: number,
        employeeId: string,
        direction: 'arrival' | 'departure',
    ): CancelablePromise<{
        commute_time: number | null;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/commute_times',
            query: {
                'commute_type': commuteType,
                'tracking_time': trackingTime,
                'tracking_latitude': trackingLatitude,
                'tracking_longitude': trackingLongitude,
                'employee_id': employeeId,
                'direction': direction,
            },
            errors: {
                422: `returns an error if azure maps error is raised`,
            },
        });
    }
}
