import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";
import { type ReactNode, useMemo } from "react";
import { NavLink, useLocation } from "react-router";

import {
  isActivePath,
  useEnabledNavigationDefinitions,
} from "~components/useNavigationDefinitions.tsx";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { classList } from "~lib/classList.ts";

import type {
  NavigationGroup,
  NavigationItem,
} from "~components/navigationDefinitions";

import styles from "./PageGroup.module.scss";

interface PageGroupProps {
  children?: ReactNode;
  navigationGroup: NavigationGroup;
}

export function PageGroup({
  children,
  navigationGroup: { titleTranslationKey },
}: PageGroupProps) {
  const t = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const { navigationDefinitions } = useEnabledNavigationDefinitions();
  const group = navigationDefinitions.find(
    (g) => g.titleTranslationKey === titleTranslationKey,
  );
  return (
    <div className={classList(styles.container, isMd && styles.isDesktop)}>
      {isMd && (
        <div className={styles.tabBar}>
          <h1 className={styles.title}>{t[titleTranslationKey]}</h1>
          <div className={styles.tabs}>
            <GroupTabs items={group?.items || []} />
          </div>
        </div>
      )}
      <div className={styles.content}>{children}</div>
    </div>
  );
}

function GroupTabs({ items }: { items: NavigationItem[] }) {
  const { number: tenantNumber } = useCurrentTenant();
  const t = useTranslation();
  const { pathname } = useLocation();

  const activeTabPath = useMemo(
    () =>
      items
        .map((item) => item.route.path)
        .find((path) => isActivePath(pathname, path)),
    [pathname, items],
  );

  return (
    <Tabs value={activeTabPath}>
      {items.map((item) => (
        <Tab
          key={item.route.path}
          value={item.route.path}
          component={NavLink}
          className={styles.tab}
          to={item.route.link({ tenantNumber })}
          label={
            item.titleTranslationKey
              ? t[item.titleTranslationKey]
              : item.route.path
          }
        />
      ))}
    </Tabs>
  );
}
