import TimeIcon from "@mui/icons-material/AccessTimeFilled";
import ControllingIcon from "@mui/icons-material/Balance";
import HealthIcon from "@mui/icons-material/HealthAndSafety";
import ResourcesIcon from "@mui/icons-material/MiscellaneousServices";
import PlanningIcon from "@mui/icons-material/Subtitles";
import compact from "lodash/compact";
import { Outlet, Route } from "react-router";

import {
  LazyAbilities,
  LazyAbsences,
  LazyBookedHours,
  LazyConstructionSiteList,
  LazyConstructionSitePlanning,
  LazyDailySummary,
  LazyEmployeeAdministration,
  LazyHoursEvaluation,
  LazyInvalidConstructionSitesOverview,
  LazyInvalidProjectsOverview,
  LazyInvalidToolsOverview,
  LazyKeyFigures,
  LazyMonitoring,
  LazyMyTimeEntries,
  LazyOrganigram,
  LazyProjectsJournal,
  LazyStats,
  LazySurcharges,
  LazyTeamAdministration,
  LazyTeamPlanning,
  LazyTimeApproval,
  LazyTimeApprovalExport,
  LazyTimeTrackingOverview,
  LazyTimeTrackingRunningView,
  LazyTimeTrackingStartViewManagerAccount,
  LazyTimeTrackingStartViewTraining,
  LazyTimeTrackingStartViewWorkPackage,
  LazyToolAdministration,
  LazyToolsCostCenters,
  LazyWageTypes,
  LazyWorkPackageAdministration,
} from "./lazyLoadedComponents.ts";
import { ROUTES } from "./routeDefinitions.ts";
import { TimeTrackingOutlet } from "./TimeManagement/TimeTracking/TimeTrackingOutlet.tsx";

import type { Route as RouteType } from "./routeDefinitions.ts";
import type SvgIcon from "@mui/material/SvgIcon";
import type { RouteProps } from "react-router";
import type {
  NonNullTranslationKey,
  TranslationKey,
} from "~contexts/I18nContext/I18nContext.tsx";

export interface RouteWithProps {
  route: RouteType<{ tenantNumber: string | number }>;
  routeProps: RouteProps;
}
export interface NavigationItem extends RouteWithProps {
  titleTranslationKey: NonNullTranslationKey;
  descriptionTranslationKey?: TranslationKey;
  betaFeature: boolean;
  // this is currently not applied on route level. If you need to disable routes, you need to build it
  featureToggle?: string;
}

export interface NavigationGroup {
  titleTranslationKey: NonNullTranslationKey & `navigationGroup_${string}`;
  IconComponent?: typeof SvgIcon;
  items: NavigationItem[];
}

export type NavigationDefinitions = {
  items: NavigationItem[];
  titleTranslationKey: NonNullTranslationKey & `navigationGroup_${string}`;
  IconComponent?: typeof SvgIcon;
}[];
const planningGroup: NavigationGroup = {
  titleTranslationKey: "navigationGroup_planning",
  IconComponent: PlanningIcon,
  items: compact([
    {
      titleTranslationKey: "constructionSites",
      descriptionTranslationKey: "planningDescription",
      route: ROUTES.constructionSites,
      betaFeature: false,
      routeProps: {
        path: ROUTES.constructionSites.path,
        element: <Outlet />,
        children: (
          <>
            <Route index element={<LazyConstructionSiteList />} />
            <Route
              path={ROUTES.constructionSite.path}
              element={<LazyConstructionSitePlanning />}
            />
          </>
        ),
      },
    },
    {
      titleTranslationKey: "workPackageAdministration",
      descriptionTranslationKey: "workPackageAdministrationDescription",
      route: ROUTES.workPackages,
      betaFeature: false,
      routeProps: {
        path: ROUTES.workPackages.path,
        element: <LazyWorkPackageAdministration />,
      },
    },
    {
      titleTranslationKey: "teamPlanning",
      descriptionTranslationKey: "teamPlanningDescription",
      route: ROUTES.teamPlanning,
      betaFeature: false,
      routeProps: {
        path: ROUTES.teamPlanning.path,
        element: <LazyTeamPlanning />,
      },
    },
    {
      titleTranslationKey: "teamAdministration",
      descriptionTranslationKey: "teamAdministrationDescription",
      route: ROUTES.teams,
      betaFeature: false,
      routeProps: {
        path: ROUTES.teams.path,
        element: <LazyTeamAdministration />,
      },
    },
    {
      titleTranslationKey: "absences",
      descriptionTranslationKey: "absencesDescription",
      route: ROUTES.absences,
      betaFeature: false,
      routeProps: {
        path: ROUTES.absences.path,
        element: <LazyAbsences />,
      },
    },
  ]),
};

const timeGroup: NavigationGroup = {
  titleTranslationKey: "navigationGroup_time",
  IconComponent: TimeIcon,
  items: [
    {
      titleTranslationKey: "timeTracking",
      descriptionTranslationKey: "timeTrackingDescription",
      route: ROUTES.timeTrackingStart,
      betaFeature: false,
      routeProps: {
        element: <TimeTrackingOutlet />,
        path: ROUTES.timeTrackingStart.path,
        children: (
          <>
            <Route index element={<LazyTimeTrackingOverview />} />
            <Route
              path={ROUTES.timeTrackingStartTeamAssignment.path}
              element={<LazyTimeTrackingStartViewWorkPackage />}
            />
            <Route
              path={ROUTES.timeTrackingStartConstructionSiteManager.path}
              element={<LazyTimeTrackingStartViewManagerAccount />}
            />
            <Route
              path={ROUTES.timeTrackingStartTraining.path}
              element={<LazyTimeTrackingStartViewTraining />}
            />
            <Route
              path={ROUTES.timeTracking.path}
              element={<LazyTimeTrackingRunningView />}
            />
            <Route
              path={ROUTES.timeTrackingSummary.path}
              element={<LazyDailySummary />}
            />
          </>
        ),
      },
    },
    {
      titleTranslationKey: "myTimeEntries",
      descriptionTranslationKey: "myTimeEntriesDescription",
      route: ROUTES.myTimeEntries,
      betaFeature: false,
      routeProps: {
        path: ROUTES.myTimeEntries.path,
        element: <LazyMyTimeEntries />,
      },
    },
    {
      titleTranslationKey: "monitoring",
      descriptionTranslationKey: "monitoringDescription",
      route: ROUTES.monitoring,
      betaFeature: false,
      routeProps: { path: ROUTES.monitoring.path, element: <LazyMonitoring /> },
    },
    {
      titleTranslationKey: "timeApproval",
      descriptionTranslationKey: "timeApprovalDescription",
      route: ROUTES.timeApproval,
      betaFeature: false,
      routeProps: {
        path: ROUTES.timeApproval.path,
        element: <LazyTimeApproval />,
      },
    },
    {
      titleTranslationKey: "timeEntryExport",
      descriptionTranslationKey: "timeEntryExportDescription",
      route: ROUTES.timeEntryExport,
      betaFeature: false,
      routeProps: {
        path: ROUTES.timeEntryExport.path,
        element: <LazyTimeApprovalExport />,
      },
    },
  ],
};

const resourcesGroup: NavigationGroup = {
  titleTranslationKey: "navigationGroup_resources",
  IconComponent: ResourcesIcon,
  items: [
    {
      titleTranslationKey: "toolManagement",
      descriptionTranslationKey: "toolManagementDescription",
      route: ROUTES.tools,
      betaFeature: false,
      routeProps: {
        path: ROUTES.tools.path,
        element: <LazyToolAdministration />,
      },
    },
    {
      titleTranslationKey: "toolsCostCenters",
      descriptionTranslationKey: "toolsCostCentersDescription",
      route: ROUTES.toolsCostCenters,
      betaFeature: false,
      routeProps: {
        path: ROUTES.toolsCostCenters.path,
        element: <LazyToolsCostCenters />,
      },
    },
    {
      titleTranslationKey: "employeeAdministration",
      descriptionTranslationKey: "employeeAdministrationDescription",
      route: ROUTES.employees,
      betaFeature: false,
      routeProps: {
        path: ROUTES.employees.path,
        element: <LazyEmployeeAdministration />,
      },
    },
    {
      titleTranslationKey: "organigram",
      descriptionTranslationKey: "organigramExportAsPdf",
      route: ROUTES.organigram,
      betaFeature: false,
      featureToggle: "organigram",
      routeProps: {
        path: ROUTES.organigram.path,
        element: <LazyOrganigram />,
      },
    },
  ],
};

const healthGroup: NavigationGroup = {
  titleTranslationKey: "navigationGroup_health",
  IconComponent: HealthIcon,
  items: [
    {
      titleTranslationKey: "invalidConstructionSites",
      descriptionTranslationKey: "invalidConstructionSitesDescription",
      route: ROUTES.invalidConstructionSites,
      betaFeature: false,
      routeProps: {
        path: ROUTES.invalidConstructionSites.path,
        element: <LazyInvalidConstructionSitesOverview />,
      },
    },
    {
      titleTranslationKey: "invalidProjects",
      descriptionTranslationKey: "invalidProjectsDescription",
      route: ROUTES.invalidProjects,
      betaFeature: false,
      routeProps: {
        path: ROUTES.invalidProjects.path,
        element: <LazyInvalidProjectsOverview />,
      },
    },
    {
      titleTranslationKey: "invalidTools",
      descriptionTranslationKey: "invalidToolsDescription",
      route: ROUTES.invalidTools,
      betaFeature: false,
      routeProps: {
        path: ROUTES.invalidTools.path,
        element: <LazyInvalidToolsOverview />,
      },
    },
    {
      titleTranslationKey: "statistics",
      descriptionTranslationKey: "statisticsDescription",
      route: ROUTES.healthBoardStats,
      betaFeature: false,
      routeProps: {
        path: ROUTES.healthBoardStats.path,
        element: <LazyStats />,
      },
    },
  ],
};

const controllingGroup: NavigationGroup = {
  titleTranslationKey: "navigationGroup_controlling",
  IconComponent: ControllingIcon,
  items: [
    {
      titleTranslationKey: "projectsJournal",
      descriptionTranslationKey: "projectsJournalDescription",
      route: ROUTES.projectsJournal,
      betaFeature: false,
      routeProps: {
        path: ROUTES.projectsJournal.path,
        element: <LazyProjectsJournal />,
      },
    },
    {
      titleTranslationKey: "surcharges",
      descriptionTranslationKey: "surchargesDescription",
      route: ROUTES.surcharges,
      betaFeature: false,
      routeProps: { path: ROUTES.surcharges.path, element: <LazySurcharges /> },
    },
    {
      titleTranslationKey: "manageWageTypes",
      descriptionTranslationKey: "manageWageTypesDescription",
      route: ROUTES.wageTypes,
      betaFeature: false,
      routeProps: {
        path: ROUTES.wageTypes.path,
        element: <LazyWageTypes />,
      },
    },
    {
      titleTranslationKey: "bookedHours",
      descriptionTranslationKey: "bookedHoursDescription",
      route: ROUTES.bookedHours,
      betaFeature: false,
      routeProps: {
        path: ROUTES.bookedHours.path,
        element: <LazyBookedHours />,
      },
    },
    {
      titleTranslationKey: "controllingKeyFigures",
      descriptionTranslationKey: "controllingKeyFiguresDescription",
      route: ROUTES.keyFigures,
      betaFeature: false,
      routeProps: { path: ROUTES.keyFigures.path, element: <LazyKeyFigures /> },
    },
    {
      titleTranslationKey: "controllingHoursEvaluations",
      descriptionTranslationKey: "controllingHoursEvaluationsDescription",
      route: ROUTES.hoursEvaluation,
      betaFeature: false,
      routeProps: {
        path: ROUTES.hoursEvaluation.path,
        element: <LazyHoursEvaluation />,
      },
    },
  ],
};

export const navigationDefinitions: NavigationGroup[] = [
  planningGroup,
  timeGroup,
  resourcesGroup,
  healthGroup,
  controllingGroup,
];

export const hiddenRoutes: RouteWithProps[] = [
  {
    route: ROUTES.abilities,
    routeProps: {
      path: ROUTES.abilities.path,
      element: <LazyAbilities />,
    },
  },
];
