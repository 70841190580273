import { useContext, useEffect } from "react";

import { useCurrentEmployeeTenants } from "~api/tenants.ts";
import { ROUTES } from "~components/routeDefinitions.ts";
import { Broken } from "~components/shared/ErrorPages/Broken.tsx";
import { Forbidden } from "~components/shared/ErrorPages/Forbidden.tsx";
import { TenantForbidden } from "~components/shared/ErrorPages/TenantForbidden.tsx";
import { Spinner } from "~components/shared/Loading/Spinner/Spinner.tsx";
import { useBusinessConfig } from "~contexts/BusinessConfigContext/BusinessConfigContext.tsx";
import { CurrentTenantContext } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";

import type { ReactElement } from "react";

interface RequireAccessProps {
  children: ReactElement<unknown>;
}

export function RequireTenantAccess({ children }: RequireAccessProps) {
  const { tenants: configTenants } = useBusinessConfig();
  const { data: tenants, isError: isTenantsError } =
    useCurrentEmployeeTenants();
  const { tenantNumber: tenantNumberFromRoute } = ROUTES.dashboard.useParams();
  const {
    setCurrentTenant,
    currentTenant,
    isInitialized: isCurrentTenantInitialized,
  } = useContext(CurrentTenantContext);

  useEffect(() => {
    if (tenants) {
      setCurrentTenant(
        tenants.find(({ number }) => String(number) === tenantNumberFromRoute),
      );
    }
  }, [tenants, tenantNumberFromRoute, setCurrentTenant]);

  if (isTenantsError) {
    return <Broken />;
  }

  if (
    isCurrentTenantInitialized &&
    !currentTenant &&
    tenants &&
    tenants.length > 0
  ) {
    const forbiddenTenantName = configTenants.find(
      (t) => t.tenant_number === Number(tenantNumberFromRoute),
    )?.name;
    return (
      <TenantForbidden
        forbiddenTenantName={forbiddenTenantName}
        redirectToTenant={tenants[0]}
      />
    );
  }

  if (isCurrentTenantInitialized && tenants && tenants.length === 0) {
    return <Forbidden />;
  }

  if (isCurrentTenantInitialized && currentTenant) {
    return children;
  }

  return <Spinner />;
}
