import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { tenantKeys } from "~api/tenants.ts";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";
import { FeaturesService } from "~generated";

export const featureKeys = {
  base: (tenantId: string) =>
    [...tenantKeys.detail(tenantId), "features"] as const,
  list: (tenantId: string) => [featureKeys.base(tenantId), "list"] as const,
};

export function useFeatureEnabled(featureName: string) {
  const { data: features } = useFeatures();

  return useMemo(
    () =>
      features?.find((feature) => featureName === feature.name)?.enabled ||
      false,
    [features, featureName],
  );
}

export function useFeatures() {
  const currentTenant = useCurrentTenant();

  return useQuery({
    queryKey: featureKeys.list(currentTenant.id),
    queryFn: () => getFeatures(currentTenant.id),
  });
}

async function getFeatures(tenantId: string) {
  const features = await FeaturesService.getFeatures(tenantId);
  if (!features) {
    return [];
  }
  return features.features;
}
