import { ROUTES } from "~components/routeDefinitions.ts";
import { useBusinessConfig } from "~contexts/BusinessConfigContext/BusinessConfigContext.tsx";

import { RedirectToMainTenant } from "../RedirectToMainTenant/RedirectToMainTenant.tsx";

import type { ReactElement } from "react";

interface RequireValidTenantNumberProps {
  children: ReactElement<unknown>;
}

export function RequireValidTenantNumber({
  children,
}: RequireValidTenantNumberProps) {
  const { tenantNumber } = ROUTES.dashboard.useParams();
  const { tenants } = useBusinessConfig();

  const tenantNumberValid = tenants.some(
    (t) => `${t.tenant_number}` === tenantNumber,
  );

  if (tenantNumberValid) {
    return children;
  }

  return <RedirectToMainTenant />;
}
