import HomeIcon from "@mui/icons-material/Home";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";

import {
  SidebarMenu,
  SidebarMenuNavLink,
} from "~components/App/Layout/Sidebar/SidebarMenu/SidebarMenu.tsx";
import {
  SidebarMenuGroupItemView,
  SidebarMenuGroupView,
} from "~components/App/Layout/Sidebar/SidebarMenu/SidebarMenuGroupView.tsx";
import { SidebarView } from "~components/App/Layout/Sidebar/SidebarView/SidebarView.tsx";
import { NotificationsBell } from "~components/Notifications/NotificationsBell/NotificationsBell.tsx";
import { ROUTES } from "~components/routeDefinitions.ts";
import {
  isActivePath,
  useEnabledNavigationDefinitions,
} from "~components/useNavigationDefinitions.tsx";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import type { NavigationGroup } from "~components/navigationDefinitions";

export function Sidebar() {
  const { number: tenantNumber } = useCurrentTenant();
  const t = useTranslation();
  const { pathname } = useLocation();

  const { navigationDefinitions } = useEnabledNavigationDefinitions();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleOnSidebarToggle = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    setIsSidebarOpen(false);
  }, [pathname]);

  return (
    <SidebarView
      open={isSidebarOpen}
      onSidebarToggle={handleOnSidebarToggle}
      title="grewe-gruppe App"
    >
      <SidebarMenu sidebarOpen={isSidebarOpen}>
        <SidebarMenuNavLink
          end
          to={ROUTES.dashboard.link({ tenantNumber })}
          title={t.startPage}
          icon={<HomeIcon />}
        />
        <SidebarMenuNavLink
          end
          to={ROUTES.notifications.link({ tenantNumber })}
          title={t.notificationsTitle}
          icon={<NotificationsBell />}
        />
        {navigationDefinitions.map((group) => (
          <SidebarMenuGroup
            key={group.titleTranslationKey}
            sidebarOpen={isSidebarOpen}
            {...group}
          />
        ))}
      </SidebarMenu>
    </SidebarView>
  );
}

function SidebarMenuGroup({
  items,
  IconComponent,
  titleTranslationKey,
  sidebarOpen,
}: NavigationGroup & { sidebarOpen: boolean }) {
  const { number: tenantNumber } = useCurrentTenant();
  const t = useTranslation();
  const theme = useTheme();
  const isMinSm = useMediaQuery(theme.breakpoints.up("sm"));
  const { pathname } = useLocation();

  const active = useMemo(
    () =>
      items
        .map((item) => item.route.path)
        .some((path) => isActivePath(pathname, path)),
    [pathname, items],
  );

  return (
    <SidebarMenuGroupView
      active={active}
      variant={sidebarOpen || !isMinSm ? "collapsible" : "popover"}
      key={titleTranslationKey}
      title={t[titleTranslationKey]}
      icon={IconComponent && <IconComponent />}
    >
      {items.map((item) => (
        <SidebarMenuGroupItemView
          key={item.titleTranslationKey}
          title={t[item.titleTranslationKey]}
          to={item.route.link({ tenantNumber })}
        />
      ))}
    </SidebarMenuGroupView>
  );
}
