import Fade from "@mui/material/Fade";
import Skeleton from "@mui/material/Skeleton";
import { useLayoutEffect, useState } from "react";

import { classList } from "~lib/classList.ts";

import { FullscreenSpinner } from "../FullscreenSpinner/FullscreenSpinner";

import styles from "./ContentSkeleton.module.scss";

interface ContentSkeletonProps {
  withPadding?: boolean;
  withBackground?: boolean;
  contentType?: "page" | "dialog" | "toolbarContent";
}

export function ContentSkeleton({
  withPadding = false,
  withBackground = false,
  contentType = "page",
}: ContentSkeletonProps) {
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const showLoadingSpinnerTimeout = 5000;

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoadingSpinner(true);
    }, showLoadingSpinnerTimeout);

    return () => clearTimeout(timeout);
  }, [setShowLoadingSpinner, showLoadingSpinnerTimeout]);

  return (
    <div
      className={classList(
        styles.container,
        styles[`type_${contentType}`],
        withPadding && styles.withPadding,
        withBackground && styles.withBackground,
      )}
      data-testid="content-skeleton"
    >
      {contentType === "page" && <PageSkeleton />}
      {contentType === "dialog" && <DialogSkeleton />}
      {contentType === "toolbarContent" && <ToolbarContentSkeleton />}
      <Fade in={showLoadingSpinner}>
        <div className={classList(styles.overlay)}>
          {showLoadingSpinner && <FullscreenSpinner />}
        </div>
      </Fade>
    </div>
  );
}

function ToolbarContentSkeleton() {
  return (
    <div className={styles.toolbarContent}>
      <Skeleton variant="rounded" className={styles.toolbarContent__skeleton} />
    </div>
  );
}

function PageSkeleton() {
  return (
    <div className={styles.page}>
      <div className={styles.page__toolbar}>
        <div>
          <Skeleton
            variant="rounded"
            width="35%"
            className={styles.page__toolbar__skeleton}
          />
          <Skeleton
            variant="rounded"
            width="15%"
            className={styles.page__toolbar__skeleton}
          />
        </div>
        <Skeleton
          variant="rounded"
          width="25%"
          className={styles.page__toolbar__skeleton}
        />
      </div>
      <Skeleton variant="rounded" className={styles.page__content} />
    </div>
  );
}

function DialogSkeleton() {
  return (
    <div className={styles.dialog}>
      <div className={styles.dialog__intro}>
        <Skeleton variant="text" width={300} />
        <Skeleton variant="text" />
      </div>
      <Skeleton className={styles.dialog__content} variant="rounded" />
      <Skeleton className={styles.dialog__footer} variant="rounded" />
    </div>
  );
}
