import CloseIcon from "@mui/icons-material/Close";
import MuiDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import React from "react";
import Draggable from "react-draggable";

import { IconWithLabel } from "~components/shared/Icon/IconWithLabel.tsx";
import { isTouchDevice } from "~components/shared/isTouchDevice.ts";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";
import { classList } from "~lib/classList.ts";

import type { DialogProps as MuiDialogProps } from "@mui/material/Dialog";
import type { PaperProps } from "@mui/material/Paper";
import type { TransitionProps } from "@mui/material/transitions";
import type { RefObject } from "react";

import styles from "./Dialog.module.scss";

export interface DialogProps extends MuiDialogProps {
  dialogTitle?: React.ReactNode;
  icon?: React.ReactNode;
  isCancelable?: boolean;
  isDraggable?: boolean;
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<unknown>;
    },
    ref: React.Ref<unknown>,
  ) => <Slide direction="up" ref={ref} {...props} />,
);
Transition.displayName = "Transition";

export function Dialog({
  children,
  dialogTitle,
  icon,
  TransitionComponent,
  onClose,
  isCancelable = true,
  isDraggable = false,
  ...props
}: DialogProps) {
  const t = useTranslation();

  const isReallyDraggable = isDraggable && !isTouchDevice();

  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }

    if (onClose) {
      onClose(event, reason);
    }
  };

  return (
    <MuiDialog
      TransitionComponent={TransitionComponent || Transition}
      onClose={handleClose}
      PaperComponent={isReallyDraggable ? DraggablePaper : Paper}
      className={styles.container}
      {...props}
    >
      <DialogTitle
        className={classList(
          styles.title,
          isReallyDraggable && styles.draggable,
        )}
      >
        <IconWithLabel icon={icon}>{dialogTitle}</IconWithLabel>
        {isCancelable && onClose && (
          <IconButton
            size="small"
            aria-label={t.close}
            color="inherit"
            onClick={(event) => onClose(event, "escapeKeyDown")}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      {children}
    </MuiDialog>
  );
}

function DraggablePaper(props: PaperProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <Draggable
      nodeRef={ref as RefObject<HTMLDivElement>}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} ref={ref} />
    </Draggable>
  );
}
