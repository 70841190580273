import { isAfter } from "date-fns/isAfter";
import { isBefore } from "date-fns/isBefore";
import { isSameDay } from "date-fns/isSameDay";

import { CommuteDirection } from "~components/TimeManagement/shared/types/CommuteDirection.ts";
import { Resource as APIResource, CommuteType } from "~generated";

import {
  timeEntriesGroupedByDateAndResourceKey,
  timeEntriesGroupedByDateAndTimeTrackingKey,
} from "./groupTimeEntries.ts";

import type { Anomalies, EmbeddedTimeEntry } from "./EmbeddedTimeEntry.ts";

type Direction = "arrival" | "departure";
interface Dictionary<T> {
  [index: string]: T;
}

type GetAnomaliesArgs = {
  timeEntry: EmbeddedTimeEntry;
  timeEntriesGroupedByDateAndEmployee: Dictionary<EmbeddedTimeEntry[]>;
  timeEntriesGroupedByDateAndTimeTracking: Dictionary<EmbeddedTimeEntry[]>;
};

export function getAnomalies({
  timeEntry,
  timeEntriesGroupedByDateAndEmployee,
  timeEntriesGroupedByDateAndTimeTracking,
}: GetAnomaliesArgs): Anomalies {
  const exceedsMaxHours = isExceedingMaxHours(
    timeEntry,
    timeEntriesGroupedByDateAndEmployee,
  );

  const employeeMultipleArrivalCommute = hasEmployeeMoreThanOneCommute(
    "arrival",
    timeEntry,
    timeEntriesGroupedByDateAndEmployee,
  );

  const employeeMultipleDepartureCommute = hasEmployeeMoreThanOneCommute(
    "departure",
    timeEntry,
    timeEntriesGroupedByDateAndEmployee,
  );

  const multipleArrivalDriversOnTimeTracking = moreThanOneDriver(
    "arrival",
    timeEntry,
    timeEntriesGroupedByDateAndTimeTracking,
  );

  const multipleDepartureDriversOnTimeTracking = moreThanOneDriver(
    "departure",
    timeEntry,
    timeEntriesGroupedByDateAndTimeTracking,
  );

  const arrivalCommuteTimeBiggerThanTrackingSwitchTime =
    getArrivalCommuteTimeBiggerThanTrackingSwitchTime(
      timeEntry,
      timeEntriesGroupedByDateAndEmployee,
    );

  const departureCommuteTimeBiggerThanTrackingSwitchTime =
    getDepartureCommuteTimeBiggerThanTrackingSwitchTime(
      timeEntry,
      timeEntriesGroupedByDateAndEmployee,
    );

  return {
    exceedsMaxHours,
    employeeMultipleArrivalCommute,
    employeeMultipleDepartureCommute,
    multipleArrivalDriversOnTimeTracking,
    multipleDepartureDriversOnTimeTracking,
    arrivalCommuteTimeBiggerThanTrackingSwitchTime,
    departureCommuteTimeBiggerThanTrackingSwitchTime,
    commuteTimeWithoutCalculation: timeEntry.hasCommuteTimeErrors,
  };
}

function isExceedingMaxHours(
  timeEntry: EmbeddedTimeEntry,
  timeEntriesGroupedByDateAndEmployee: Dictionary<EmbeddedTimeEntry[]>,
) {
  const timeEntries =
    timeEntriesGroupedByDateAndEmployee[
      timeEntriesGroupedByDateAndResourceKey(timeEntry)
    ];

  const timeEntriesForEmployeeAtSameDate = timeEntries.filter(
    sameDateSameResourceTimeEntriesFilter(timeEntry),
  );
  const totalHours = timeEntriesForEmployeeAtSameDate.reduce(
    (acc, t) => acc + t.netDurationSeconds / 60 / 60,
    0,
  );
  return totalHours > 10;
}

function moreThanOneDriver(
  direction: Direction,
  timeEntry: EmbeddedTimeEntry,
  timeEntriesGroupedByDateAndTimeTracking: Dictionary<EmbeddedTimeEntry[]>,
) {
  if (timeEntry.resource.type !== APIResource.type.EMPLOYEE) {
    return false;
  }

  if (!isDriverCommuteType(direction, timeEntry)) {
    return false;
  }

  const timeEntries =
    timeEntriesGroupedByDateAndTimeTracking[
      timeEntriesGroupedByDateAndTimeTrackingKey(timeEntry)
    ];

  const timeEntriesForTimeTrackingAtSameDate = timeEntries.filter(
    (other) =>
      onSameDate(timeEntry, other) &&
      timeEntry.time_tracking_id === other.time_tracking_id,
  );

  const drivers = timeEntriesForTimeTrackingAtSameDate.filter(
    (timeEntryOfDay) => isDriverCommuteType(direction, timeEntryOfDay),
  );

  return drivers.length > 1;
}

function isDriverCommuteType(
  direction: Direction,
  timeEntry: EmbeddedTimeEntry,
) {
  return [CommuteType.DRIVER_HOME, CommuteType.DRIVER_SITE].includes(
    timeEntry[`${direction}_commute_type`],
  );
}

function hasEmployeeMoreThanOneCommute(
  direction: Direction,
  timeEntry: EmbeddedTimeEntry,
  timeEntriesGroupedByDateAndEmployee: Dictionary<EmbeddedTimeEntry[]>,
): boolean {
  if (timeEntry.resource.type !== APIResource.type.EMPLOYEE) {
    return false;
  }

  if (timeEntry[`${direction}_commute_type`] === CommuteType.NO_COMMUTE) {
    return false;
  }

  const timeEntries =
    timeEntriesGroupedByDateAndEmployee[
      timeEntriesGroupedByDateAndResourceKey(timeEntry)
    ];

  const timeEntriesForEmployeeAtSameDate = timeEntries.filter(
    sameDateSameResourceTimeEntriesFilter(timeEntry),
  );

  const commuteEntries = timeEntriesForEmployeeAtSameDate.filter(
    (timeEntryOfDay) =>
      timeEntryOfDay[`${direction}_commute_type`] !== CommuteType.NO_COMMUTE,
  );

  return commuteEntries.length > 1;
}

const ALLOWED_COMMUTE_TIME_DEVIATION_IN_PERCENT = 10;
const PASSENGER_COMMUTE_TIME_FACTOR = 2;

function getArrivalCommuteTimeBiggerThanTrackingSwitchTime(
  timeEntry: EmbeddedTimeEntry,
  timeEntriesGroupedByDateAndEmployee: Dictionary<EmbeddedTimeEntry[]>,
): boolean {
  if (!timeEntry.startTime) {
    return false;
  }
  if (
    timeEntry.arrival_commute_time_seconds === null ||
    timeEntry.arrival_commute_time_seconds === undefined
  ) {
    return false;
  }

  const timeEntries =
    timeEntriesGroupedByDateAndEmployee[
      timeEntriesGroupedByDateAndResourceKey(timeEntry)
    ];

  const timeEntryBefore = findTimeEntryBefore(timeEntry, timeEntries);

  if (!timeEntryBefore) {
    return false;
  }

  const commuteTimeSeconds =
    getRealArrivalCommuteTime(timeEntry) *
    (1 - ALLOWED_COMMUTE_TIME_DEVIATION_IN_PERCENT / 100);

  return (
    commuteTimeSeconds > trackingSwitchTimeSeconds(timeEntryBefore, timeEntry)
  );
}

function getRealArrivalCommuteTime(timeEntry: EmbeddedTimeEntry) {
  if (!timeEntry.arrival_commute_time_seconds) {
    return 0;
  }
  return isPassengerCommuteType(timeEntry, CommuteDirection.ARRIVAL)
    ? PASSENGER_COMMUTE_TIME_FACTOR * timeEntry.arrival_commute_time_seconds
    : timeEntry.arrival_commute_time_seconds;
}

function isPassengerCommuteType(
  timeEntry: EmbeddedTimeEntry,
  direction: Direction,
) {
  return [CommuteType.PASSENGER_HOME, CommuteType.PASSENGER_SITE].includes(
    timeEntry[`${direction}_commute_type`],
  );
}

function getDepartureCommuteTimeBiggerThanTrackingSwitchTime(
  timeEntry: EmbeddedTimeEntry,
  timeEntriesGroupedByDateAndEmployee: Dictionary<EmbeddedTimeEntry[]>,
): boolean {
  if (!timeEntry.stopTime) {
    return false;
  }
  if (
    timeEntry.departure_commute_time_seconds === null ||
    timeEntry.departure_commute_time_seconds === undefined
  ) {
    return false;
  }

  const timeEntries =
    timeEntriesGroupedByDateAndEmployee[
      timeEntriesGroupedByDateAndResourceKey(timeEntry)
    ];

  const timeEntryAfter = findTimeEntryAfter(timeEntry, timeEntries);

  if (!timeEntryAfter) {
    return false;
  }

  const commuteTimeSeconds =
    getRealDepartureCommuteTime(timeEntry) *
    (1 - ALLOWED_COMMUTE_TIME_DEVIATION_IN_PERCENT / 100);

  return (
    commuteTimeSeconds > trackingSwitchTimeSeconds(timeEntry, timeEntryAfter)
  );
}

function getRealDepartureCommuteTime(timeEntry: EmbeddedTimeEntry) {
  if (!timeEntry.departure_commute_time_seconds) {
    return 0;
  }
  return isPassengerCommuteType(timeEntry, CommuteDirection.DEPARTURE)
    ? PASSENGER_COMMUTE_TIME_FACTOR * timeEntry.departure_commute_time_seconds
    : timeEntry.departure_commute_time_seconds;
}

function findTimeEntryBefore(
  timeEntry: EmbeddedTimeEntry,
  timeEntries: EmbeddedTimeEntry[],
) {
  return timeEntries
    .filter((t) => Boolean(t.stopTime))
    .filter((t) => !t.deleted_at)
    .sort(
      (a, b) => (b.stopTime as Date).getTime() - (a.stopTime as Date).getTime(),
    )
    .find((t) => isBefore(t.stopTime as Date, timeEntry.startTime as Date));
}

function findTimeEntryAfter(
  timeEntry: EmbeddedTimeEntry,
  timeEntries: EmbeddedTimeEntry[],
) {
  return timeEntries
    .filter((t) => Boolean(t.startTime))
    .filter((t) => !t.deleted_at)
    .sort(
      (a, b) =>
        (a.startTime as Date).getTime() - (b.startTime as Date).getTime(),
    )
    .find((t) => isAfter(t.startTime as Date, timeEntry.stopTime as Date));
}

function trackingSwitchTimeSeconds(
  timeEntryBefore: EmbeddedTimeEntry,
  timeEntryAfter: EmbeddedTimeEntry,
) {
  return (
    ((timeEntryAfter.startTime as Date).getTime() -
      (timeEntryBefore.stopTime as Date).getTime()) /
    1000
  );
}

function sameDateSameResourceTimeEntriesFilter(
  timeEntry: EmbeddedTimeEntry,
): (other: EmbeddedTimeEntry) => boolean {
  return (other) =>
    onSameDate(timeEntry, other) &&
    other.originalResource.id === timeEntry.originalResource.id;
}

function onSameDate(
  timeEntry: EmbeddedTimeEntry,
  other: EmbeddedTimeEntry,
): boolean {
  return (
    !other.deleted_at &&
    !!other.startTime &&
    !!timeEntry.startTime &&
    isSameDay(other.startTime, timeEntry.startTime)
  );
}
