import { matchPath, useParams } from "react-router";

import { Ability } from "~auth/Ability.ts";

import type { UnknownRecord } from "type-fest";

export const ROUTES = {
  abilities: tenantRoute("/abilities", [Ability.canAccessApp]),
  absences: tenantRoute(`/planning/absences`, [Ability.canManageAbsences]),
  healthBoardStats: tenantRoute(`/health/stats`, [Ability.canAccessAppStats]),
  bookedHours: tenantRoute(`/controlling/booked-hours`, [
    Ability.canAccessBookedHoursOfEachEmployee,
  ]),
  constructionSite: tenantRoute<{ constructionSiteId: string }>(
    `/planning/construction-sites/:constructionSiteId`,
    [Ability.canManageConstructionSites, Ability.canAccessPlanning],
  ),
  constructionSites: tenantRoute(`/planning/construction-sites`, [
    Ability.canAccessPlanning,
  ]),
  projectsJournal: tenantRoute(`/controlling/projects`, [
    Ability.canAccessControlling,
  ]),
  hoursEvaluation: tenantRoute(`/controlling/hours-evaluation`, [
    Ability.canAccessHoursEvaluation,
  ]),
  dashboard: tenantRoute("", [Ability.canAccessApp]),
  employees: tenantRoute(`/resources/employees`, [
    Ability.canViewOrganigram,
    Ability.canManageOrganigram,
    Ability.canManageToolsAccounting,
  ]),
  invalidConstructionSites: tenantRoute(`/health/invalid-construction-sites`, [
    Ability.canAccessPlanning,
  ]),
  invalidProjects: tenantRoute(`/health/invalid-projects`, [
    Ability.canAccessPlanning,
  ]),
  invalidTools: tenantRoute(`/health/invalid-tools`, [Ability.canAccessApp]),
  keyFigures: tenantRoute(`/controlling/key-figures`, [
    Ability.canManageSurchargeRate,
  ]),
  monitoring: tenantRoute(`/time/monitoring`, [
    Ability.canMonitorActiveTimeEntries,
  ]),
  myTimeEntries: tenantRoute(`/time/history`, [Ability.canAccessApp]),
  notifications: tenantRoute(`/notifications`, [Ability.canAccessApp]),
  organigram: tenantRoute(`/resources/organigram`, [Ability.canViewOrganigram]),
  surcharges: tenantRoute(`/controlling/surcharges`, [
    Ability.canManageSurchargeRate,
  ]),
  teamPlanning: tenantRoute(`/planning/schedule`, [Ability.canAccessPlanning]),
  teams: tenantRoute(`/planning/teams`, [Ability.canEditConstructionSiteTeams]),
  testError: route("/app/testerror", [Ability.canAccessApp]),
  timeApproval: tenantRoute(`/time/approval`, [Ability.canManageTimeEntries]),
  timeEntryExport: tenantRoute(`/time/export`, [Ability.canExportTimeEntries]),
  timeTracking: tenantRoute<{ timeTrackingId: string }>(
    `/time/tracking/running/:timeTrackingId`,
    [Ability.canAccessApp],
  ),
  timeTrackingStart: tenantRoute(`/time/tracking`, [Ability.canAccessApp]),
  timeTrackingStartConstructionSiteManager: tenantRoute(
    `/time/tracking/construction-site-manager`,
    [Ability.canAccessApp],
  ),
  timeTrackingStartTeamAssignment: tenantRoute<{ teamAssignmentId: string }>(
    `/time/tracking/team-assignment/:teamAssignmentId`,
    [Ability.canAccessApp],
  ),
  timeTrackingStartTraining: tenantRoute(`/time/tracking/training`, [
    Ability.canAccessApp,
  ]),
  timeTrackingSummary: tenantRoute(`/time/tracking/today`, [
    Ability.canAccessApp,
  ]),
  toolDeeplink: route<{ toolId: string }>("/app/tools/:toolId", [
    Ability.canAccessApp,
  ]),
  tools: tenantRoute(`/resources/tools`, [Ability.canAccessApp]),
  toolsCostCenters: tenantRoute(`/resources/tools-cost-centers`, [
    Ability.canManageToolsAccounting,
  ]),
  wageTypes: tenantRoute(`/controlling/wage-types`, [
    Ability.canAccessWageTypes,
  ]),
  workPackages: tenantRoute(`/planning/work-packages`, [
    Ability.canAccessPlanning,
  ]),
};

export function getMatchedRouteForPathname<T extends UnknownRecord>(
  pathname: string,
): { route?: Route<T>; params: T } {
  const matchedRoute = Object.values(ROUTES)
    .map((routeObj) => ({
      route: routeObj as unknown as Route<T>,
      params: matchPath(routeObj.path, pathname)?.params as T | null,
    }))
    .find((match): match is { route: Route<T>; params: T } =>
      Boolean(match.params),
    );

  return matchedRoute ?? { params: {} as T };
}

export type Route<T extends UnknownRecord> = {
  path: string;
  link: (params: T, searchParams?: URLSearchParams) => string;
  useParams: () => T;
  permissions: Ability[];
};

export function route<T extends Record<string, string | number>>(
  path: string,
  permissions: Ability[],
): Route<T> {
  return {
    path,
    link: generateLinkFn<T>(path),
    useParams: useParams as () => T,
    permissions,
  };
}

function generateLinkFn<T extends UnknownRecord>(path: string) {
  return function link(params: T, searchParams?: URLSearchParams) {
    let linkStr = path;
    Object.entries(params).forEach(([key, value]) => {
      linkStr = linkStr.replace(`:${String(key)}`, String(value));
    });

    const searchParamsStr = searchParams ? `?${searchParams?.toString()}` : "";
    linkStr = `${linkStr}${searchParamsStr}`;

    return linkStr;
  };
}

export function tenantRoute<T extends Record<string, string | number>>(
  path: string,
  permissions: Ability[],
): Route<{ tenantNumber: string | number } & T> {
  return route(`/app/:tenantNumber${path}`, permissions);
}
